import React from 'react'
import { Outlet, Navigate } from "react-router-dom";
import { useAuthStatus } from "../hooks/useAuthStatus";
import Spinner from "./Spinner";
import { useState, useEffect } from "react";
import { getAuth} from "firebase/auth";

function ProjectPrivateRoute() {
    const [ currentUserEmail,setCurrentUserEmail] = useState('');

  useEffect(() => {
    //----------------------------------------------
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });
    return unsubscribe;
  }, []);
  console.log(currentUserEmail);
  const { loggedIn, checkingStatus } = useAuthStatus();
  if (checkingStatus) {
    return <Spinner />;
  }
  return loggedIn && ( currentUserEmail==='waseem.klazina@gmail.com' || currentUserEmail==='tech.prop10x@gmail.com' || currentUserEmail === 'abhay240103@gmail.com') ? <Outlet /> : <Navigate to="/sign-in" />;
}

export default ProjectPrivateRoute;