import React, { useState } from "react";

const LandZone = () => {
  const [landZone, setLandZone] = useState("");
  return (
    <div>
      <label htmlFor="landZone" className="block mb-1">
        Land Zone
      </label>
      <input
        type="text"
        id="landZone"
        value={landZone}
        onChange={(e) => setLandZone(e.target.value)}
        placeholder="Enter Land Zone"
        maxLength="32"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
    </div>
  );
};

export default LandZone;
