import React from 'react'
import FloorsAllowed from '../inputcomponents/FloorsAllowed'
import OpenSides from '../inputcomponents/OpenSides'
import RoadWidth from '../inputcomponents/RoadWidth'
import AreaLabel from '../labels/AreaLabel'
import SuperArea from '../inputcomponents/SuperArea'
import Price from '../inputcomponents/Price'
import PhotoUpload from '../inputcomponents/Photos'
import PossessionStatus from '../inputcomponents/PossessionStatus'
import LeaseStatus from '../inputcomponents/LeaseStatus'
import PropertyLocation from '../inputcomponents/PropertyLocation'
import LandZone from '../inputcomponents/LandZone'

const IndustrialShed = () => {
  return (
    <div className="mb-4">
        <PropertyLocation />
        <LandZone />
    <h1 className="text-lg font-semibold mb-2">Property Features</h1>

    <div className="grid grid-cols-2 gap-4">
      <FloorsAllowed />
      <OpenSides />
      <RoadWidth />
    </div>

    <div className="mb-4">
      <AreaLabel />
      <SuperArea />
    </div>
    <div>
      <h1 className="text-lg font-semibold mb-2 mt-4">
        Transaction Type, Property Availability
      </h1>
      <PossessionStatus />
      <LeaseStatus />
    </div>
    <div className="mt-4">
      <Price />
      <PhotoUpload />
    </div>
  </div>
  )
}

export default IndustrialShed
