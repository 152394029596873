import React, { useState } from 'react'

const FloorNo = () => {
  const [floorNo, setFloorNo] = useState("");
  return (
    <div>
    <label htmlFor="FloorNo" className="block mb-1">
      Floor No:
    </label>
    <select
      id="floorNo"
      value={floorNo}
      onChange={(e) => setFloorNo(e.target.value)}
      required
      className="border border-gray-300 rounded-md p-2"
    >
      <option value="default">Select an Option</option>
      <option value="LB">Lower Basement</option>
      <option value="UB">Upper Basement</option>
      <option value="G">Ground</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="13+">13+</option>
    </select>
  </div>
  )
}

export default FloorNo
