import React from "react";
import FloorsAllowed from "../inputcomponents/FloorsAllowed";
import OpenSides from "../inputcomponents/OpenSides";
import RoadWidth from "../inputcomponents/RoadWidth";
import BoundaryWall from "../inputcomponents/BoundaryWall";
import PlotDimensions from "../inputcomponents/PlotDimensions";
import CornerPlot from "../inputcomponents/CornerPlot";
import AreaLabel from "../labels/AreaLabel";
import PlotArea from "../inputcomponents/PlotArea";
import LeaseStatus from "../inputcomponents/LeaseStatus";
import Price from "../inputcomponents/Price";
import PhotoUpload from "../inputcomponents/Photos";
import PropertyLocation from "../inputcomponents/PropertyLocation";
import LandZone from "../inputcomponents/LandZone";

const CommercialLand = () => {
  return (
    <div className="mb-4">
      <PropertyLocation />
      <LandZone />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>

      <div className="grid grid-cols-2 gap-4">
        <FloorsAllowed />
        <OpenSides />
        <RoadWidth />
      </div>
      <div className="gap-4 mb-4">
        <BoundaryWall />
      </div>

      <div className="mb-4">
        <AreaLabel />
        <PlotArea />
        <PlotDimensions />
        <CornerPlot />
      </div>

      <div>
        <h1 className="text-lg font-semibold mb-2 mt-4">
          Transaction Type, Property Availability
        </h1>
        <LeaseStatus />
      </div>
      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  );
};

export default CommercialLand;
