import React, { useState } from 'react'

const TotalFlats = () => {
  const [totalFlats, setTotalFlats] = useState("");
  return (
    <div>
          <label htmlFor="totalFlats" className="block mb-1">
            Total No. of Flats in Your Society:
          </label>
          <select
            id="totalFlats"
            value={totalFlats}
            onChange={(e) => setTotalFlats(e.target.value)}
            className="border border-gray-300 rounded-md p-2"
          >
            <option value="default">Select an Option</option>
            <option value="<50">{"<"}50</option>
            <option value="50-100">50-100</option>
            <option value=">100">&gt;100</option>
          </select>
        </div>
  )
}

export default TotalFlats
