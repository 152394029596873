import React, { useState } from 'react'

const FurnishedStatus = () => {
  const [furnishedStatus, setFurnishedStatus] = useState("");
  return (
    <div>
          <label htmlFor="furnishedStatus" className="block mb-1">
            Furnished Status:
          </label>
          <select
            id="furnishedStatus"
            value={furnishedStatus}
            onChange={(e) => setFurnishedStatus(e.target.value)}
            required
            className="border border-gray-300 rounded-md p-2"
          >
            <option value="default">Select an Option</option>
            <option value="Furnished">Furnished</option>
            <option value="Unfurnished">Unfurnished</option>
            <option value="Semi-Furnished">Semi-Furnished</option>
          </select>
        </div>
  )
}

export default FurnishedStatus
