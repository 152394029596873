import React, { useState } from 'react'

const CornerPlot = () => {

    const [isCornerPlot, setIsCornerPlot] = useState(false);
  return (
    <div className="gap-8 pb-2" >
          <div className='gap-10 mt-2 flex flex-wrap'>
        <p className="text-gray-600">Is this a corner plot?</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="isCornerPlotYes">
            <input
              type="radio"
              id="isCornerPlotYes"
              name="isCornerPlot"
              value={true}
              checked={isCornerPlot}
              onChange={() => setIsCornerPlot(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="isCornerPlotNo">
            <input
              type="radio"
              id="isCornerPlotNo"
              name="isCornerPlot"
              value={false}
              checked={!isCornerPlot}
              onChange={() => setIsCornerPlot(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  )
}

export default CornerPlot
