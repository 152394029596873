import React, { useState } from 'react'

const LeaseStatus = () => {
    const [leaseStatus, setLeaseStatus] = useState('');
  return (
    <div className="gap-8 pb-8 flex flex-wrap">
          <p className="text-gray-600">Currently Leased Out</p>
          <div className="flex items-center space-x-4">
            <label htmlFor="leaseStatusYes">
              <input
                type="radio"
                id="leaseStatusYes"
                name="leaseStatus"
                value="Yes"
                checked={leaseStatus === 'Yes'}
                onChange={() => setLeaseStatus('Yes')}
                className="mr-2"
              />
              Yes
            </label>
            <label htmlFor="leaseStatusNo">
              <input
                type="radio"
                id="leaseStatusNo"
                name="leaseStatus"
                value="No"
                checked={leaseStatus === 'No'}
                onChange={() => setLeaseStatus('No')}
                className="mr-2"
              />
              No
            </label>
          </div>
        </div>
  )
}

export default LeaseStatus
