import React, { useState } from 'react'

const AssuredReturns = () => {
    const [assuredReturns, setAssuredReturns] = useState('');
  return (
    <div className="gap-8 pb-8 flex flex-wrap">
    <p className="text-gray-600">Assured Returns</p>
    <div className="flex items-center space-x-4 mt-0">
      <label htmlFor="assuredReturnsYes">
        <input
          type="radio"
          id="assuredReturnsYes"
          name="assuredReturns"
          value="Yes"
          checked={assuredReturns === 'Yes'}
          onChange={() => setAssuredReturns('Yes')}
          className="mr-2"
        />
        Yes
      </label>
      <label htmlFor="assuredReturnsNo">
        <input
          type="radio"
          id="assuredReturnsNo"
          name="assuredReturns"
          value="No"
          checked={assuredReturns === 'No'}
          onChange={() => setAssuredReturns('No')}
          className="mr-2"
        />
        No
      </label>
    </div>
  </div>
  )
}

export default AssuredReturns
