import React from 'react'

const AreaLabel = () => {
  return (
    <div>
      <h1 className="text-lg font-semibold mb-1">Area</h1>
        <div className="grid grid-cols-2 gap-4">
            <span className="text-xs text-gray-600 font-thin mb-4"> Provide Super Area</span>
        </div>
    </div>
  )
}

export default AreaLabel
