import React, { useState } from 'react'

const WidthofEntrance = () => {
  const [ widthofEntrance, setWidthofEntrance] = useState('');
  return (
    <div>
      <div className="grid w-1/3 gap-4 mt-4">
        <div>
          <label htmlFor="widthofEntrance" className="block mb-1">
          Width of Entrance
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="widthofEntrance"
              value={widthofEntrance}
              onChange={(e) => setWidthofEntrance(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-20" // Added width
            />
            <span className="text-lg w-full text-gray-900 ml-2">ft</span> {/* Moved optional text */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WidthofEntrance
