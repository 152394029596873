import React, { useState } from 'react'

const IdealForBusiness = () => {
    const [ idealForBusiness, setIdealForBusiness ] = useState('');
  return (
    <div>
      <label htmlFor="idealForBusiness" className="block mb-1">
      Ideal For Business
          </label>
      <input
        type="text"
        id="idealForBusiness"
        value={idealForBusiness}
        onChange={(e) => setIdealForBusiness(e.target.value)}
        placeholder="Please Enter Text"
        maxLength="32"
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
    </div>
  )
}

export default IdealForBusiness
