import React, { useState } from 'react'

const GatedColony = () => {
    
  const [gatedColony, setGatedColony] = useState('');
  return (
    <div className="gap-8 pb-2" >
          <div className='mt-2 gap-10 flex flex-wrap'>
        <p className="text-gray-600">Is in a gated colony?</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="gatedColonyYes">
            <input
              type="radio"
              id="gatedColonyYes"
              name="gatedColony"
              value={true}
              checked={gatedColony}
              onChange={() => setGatedColony(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="gatedColonyNo">
            <input
              type="radio"
              id="gatedColonyNo"
              name="constructionStatus"
              value={false}
              checked={!gatedColony}
              onChange={() => setGatedColony(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  )
}

export default GatedColony
