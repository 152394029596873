import React, { useState } from 'react'

const ResidentialLocation = () => {
    const [city, setCity ] = useState('');
    const [ project, setProject ] = useState('');
  return (
    <div>
      <h1 className="lg mt-6 font-semibold">Property Location</h1>
      <label htmlFor="city" className="block mb-1">
            City
          </label>
      <input
        type="text"
        id="city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        placeholder="Enter City"
        maxLength="32"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
       <label htmlFor="project" className="block mb-1">
       Name of Project/Society
          </label>
      <input
        type="text"
        id="project"
        value={project}
        onChange={(e) => setProject(e.target.value)}
        placeholder="Name of Project/Society"
        maxLength="60"
        required
        className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
      />
    </div>
  )
}

export default ResidentialLocation;
