/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from "../assets/svg/theProperty10nobg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FiX } from 'react-icons/fi';
import { FiMenu } from "react-icons/fi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  FaAngleDown } from "react-icons/fa";
import { faBuilding, faHome, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {MdOutlineSell} from 'react-icons/md';
import {GoHome} from 'react-icons/go'
import {FcMoneyTransfer, FcCallback, FcLock} from 'react-icons/fc'
import {FaRegBuilding} from 'react-icons/fa'
import {BsPeople} from 'react-icons/bs';  

function Navbar() {
  useEffect(() => { 
    setTimeout(() => {  
      window.scrollTo(0, 0);
    }, 100);
  }, []);   
  
  const [pageState, setPageState] = useState("Sign in");
  const location = useLocation(); 
  const navigate = useNavigate();
  const auth = getAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdownprojects = () => {   
    setIsOpen((prevState) => !prevState);
  };
  const handleProjectClick = () => {
    toggleDropdownprojects();
  };

  const [ currentUserEmail,setCurrentUserEmail] = useState('');

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUserEmail(user.email);
      } else {
        setCurrentUserEmail('');
      }
    });
    return unsubscribe;
  }, []);
  // Drop down 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const handleSelectOption = (route) => {
    history.push(route);
    setShowDropdown(false); 
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const location = useLocation();
  useEffect(() => {
    setIsMobileMenuOpen(false); // Close the mobile navigation pane when a new route is opened
  }, [location]);

  function toggleMobileMenu() {   
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }
  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile");
      } else {
        setPageState("Sign in");
      }  
    });
  }, [auth]);

  function pathMatchRoute(route) {       
    return location.pathname === route;
  }   
  return (
    <div className='lg:sticky top-0 z-50 bg-slate-50'>
      <nav className="navbar">
      <div className="lg:hidden flex items-center h-full">
    <nav className="flex justify-between items-center lg:items-stretch outside z-10 relative">
  <div className="lg:hidden flex items-center z-50 ">
  <div className="mobile-menu-icon">
    {isMobileMenuOpen ? (
     ''
    ) : (
      <FiMenu
        className="cursor-pointer w-8 h-8  "
        onClick={toggleMobileMenu}/>
        )}
        </div>
  </div>
  <ul className={`hidden-nav z-50 top-3 left-0 right-10 lg:relative lg:flex menu ${isMobileMenuOpen ? 'open' : 'hidden'} bg-slate-50 h-full overflow-y-hidden`}>
  <div className="lg:hidden flex items-center flex-row-reverse h-full">
  <img src={logo} alt='logo' onClick={()=>navigate("/")} className="cursor-pointer mt-3 w-54 h-14 shrink-0 rounded-lg " style={{marginLeft: '1rem'}} />
  <div>
    {isMobileMenuOpen ? (
      <FiX
        className="cursor-pointer mt-0 w-9 h-10"
        onClick={toggleMobileMenu}
      />
    ) : (
      <FiMenu
        className="cursor-pointer w-8 h-10"
        onClick={toggleMobileMenu}
      />
    )}
  </div>
</div>
  {/* <li onClick={()=>navigate("/")}>
    <a href=" #" className="home block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <GoHome style={{ marginRight: "0.5rem" }} />
  <span>Home</span>
</div>
    </a>
  </li> */}
  <li onClick={()=>navigate("/allprojects")}>
    <a href=" #" className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <FcMoneyTransfer style={{ marginRight: "0.5rem" }} />
  <span>Buy</span>
</div>      
    </a>
  </li>
  <li onClick={()=>navigate("/sell")}>
    <a href=" #" className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <MdOutlineSell style={{ marginRight: "0.5rem" }} />
  <span>Sell</span>
</div> 
    </a>
  </li>
  <li
      className="relative"
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <div
        className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaRegBuilding style={{ marginRight: "0.5rem" }} />
          <span>Projects</span>
          <FaAngleDown style={{ marginLeft: "0.5rem" }} />
        </div>
      </div>
      {showDropdown && (
        <div className="absolute top-12 right-8 bg-white border rounded shadow-md">
          <ul className="divide-y">
          <li className="py-2 px-2 hover:bg-gray-200">
          <FontAwesomeIcon icon={faBuilding} className="mr-2" />
          <span onClick={()=>navigate('/commercialprojects')}>Commercial Project</span>
        </li>
         <li className="py-1 px-2 hover:bg-gray-200">
          <FontAwesomeIcon icon={faHome} className="mr-2" />
          <span onClick={()=>navigate('/residentialprojects')}>Residential Project</span>
        </li>
          </ul>
        </div>
      )}
    </li>
  <li  onClick={()=>navigate("/contactus")}>
    <a href=" # " className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <FcCallback style={{ marginRight: "0.5rem" }} />
  <span>Contact Us</span>
</div> 
    </a>
  </li>
  <li onClick={()=>navigate("/emi")}>
    <a href=" #" className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <GoHome style={{ marginRight: "0.5rem" }} />
  <span>Loan</span>
</div>
    </a>
  </li>
  <li  onClick={()=>navigate("/aboutus")}>
    <a href=" # " className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <BsPeople style={{ marginRight: "0.5rem" }} />
  <span>About Us</span>
</div> 
    </a>
  </li>
  <li onClick={()=>navigate("/feedback")}>
    <a href=" #" className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <GoHome style={{ marginRight: "0.5rem" }} />
  <span>Feedback</span>
</div>
    </a>
  </li>
  { <li className={`${(pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) && "border-b-[#005978]"}`} onClick={()=>navigate("/profile")}>
    <a href=" # " className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
    <div style={{ display: "flex", alignItems: "center" }}>
  <BsPeople style={{ marginRight: "0.5rem" }} />
  <span>{pageState}</span>
</div> 
    </a>
  </li> }
  {(currentUserEmail === 'info.prop10x@gmail.com' || currentUserEmail === 'tech.prop10x@gmail.com'  || currentUserEmail === 'abhay240103@gmail.com') && (
    <li>
      <a href=" # " className="block lg:inline-block m-6 ml-10 text-2xl lg:mt-0 mx-6">
      <div onClick={()=>navigate("/create-project")} style={{ display: "flex", alignItems: "center" }}>
  <FcLock style={{ marginRight: "0.5rem" }} />
  <span>Add projects</span>
   {isOpen && (
      <ul className="absolute top-full left-0 z-120 bg-white w-[13rem]">
        <li className="py-1 px-2 hover:bg-gray-200">
          <FontAwesomeIcon icon={faHome} className="mr-2" />
          <span onClick={()=>navigate('/residentialprojects')}>Residential Project</span>
        </li>
        <li className="py-2 px-2 hover:bg-gray-200">
          <FontAwesomeIcon icon={faBuilding} className="mr-2" />
          <span onClick={()=>navigate('/commercialprojects')}>Commercial Project</span>
        </li>
      </ul>
    )}
</div> 
      </a>
    </li>
    
  )}
  
</ul>
</nav>
</div>

        <div className="logo">
          {/* Hide logo and profile button when mobile menu is open */}
          {!isMobileMenuOpen && (
          <img src={logo} onClick={()=>navigate("/")} alt="Logo" className="cursor-pointer mt-2 w-54 h-14 shrink-0 rounded-lg " style={{marginLeft: '1rem'}} />)}
        </div>
        <ul className="nav-links">
          {/* <li>
            <a href="/">Home</a>
          </li> */}
          <li>
            <a href="/buy">Buy</a>
          </li>
          <li>
            <a href="/sell">Sell</a>
          </li>
          <li>
            <a href="/rent">Rent</a>
          </li>
          <li
    className={`relative cursor-pointer  font-semibold text-[#005978] border-b-[3px] border-b-transparent ${
      pathMatchRoute("/allprojects") && "border-b-[#005978]"
    }`}
    onClick={handleProjectClick}
  >
    <span className="flex items-center">
      <FontAwesomeIcon icon={faBuilding} className="mr-2" />
      Projects
      <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
    </span>
    {isOpen && (
      <ul className="absolute top-full mt-5 left-0 z-120 bg-[#f5f5f5] w-[15rem]">
        <li className="py-1  hover:bg-gray-200">
          <FontAwesomeIcon icon={faHome} className="mr-2" />
          <span onClick={()=>navigate('/residentialprojects')}>Residential Project</span>
        </li>
        <li className="py-2  hover:bg-gray-200">
          <FontAwesomeIcon icon={faBuilding} className="mr-2" />
          <span onClick={()=>navigate('/commercialprojects')}>Commercial Project</span>
        </li>
      </ul>
    )}
  </li>
          <li>
            <a href="/contactus">Contact Us</a>
          </li>
          <li>
            <a href="/aboutus">About Us</a>
          </li>
          <li>
            <a href="/create-listing">Free Listing</a>
          </li>
          {(currentUserEmail === 'info.prop10x@gmail.com' || currentUserEmail === 'abhay240103@gmail.com'  || currentUserEmail === 'tech.prop10x@gmail.com' ) && (<li className={`cursor-pointer  font-semibold text-[#005978] border-b-[3px] border-b-transparent ${pathMatchRoute("/aboutus")&&"border-b-[#005978]"}`} onClick={()=>navigate("/create-project")} >Add project</li>)}
          {pageState === "Profile" && (
            <li>
              <button
                className={`${pathMatchRoute("/profile") ? "border-b-[#005978]" : ""}`}
                onClick={() => navigate("/profile")}
              >
                {pageState}
              </button>
            </li>
          )}
          {pageState === "Sign in" && (
            <>
              <li >
                <a href="/sign-in">Sign In</a>
              </li>
            </>
          )}
           {/* <li>
          <a href="/post-property" target='_blank'>
          <button
        type="Propertyform"
        className=" w-full px-7 py-3 bg-[#70c1f3] text-zinc-50 font-medium text-sm uppercase rounded shadow-md hover:bg-neutral-50 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
      >
        Post Property
      </button></a>
          </li> */}
        </ul>
        {/* Hide logo and profile button when mobile menu is open */}
        {!isMobileMenuOpen && (
        <div className="profile-btn flex lg:hidden mt-3">
  <button className="text-[#005978] font-semibold" onClick={() => navigate("/sign-in")}>
    <i className=" fa fa-user-circle-o fa-lg" style={{ fontSize: "33px", color: "#005978"  }} />
  </button>
</div>)}
      </nav>
    </div>
  );
}
export default Navbar;