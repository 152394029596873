/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import './Emi.css';
import tata from "../assets/svg/tata.jpeg";
import idfc from "../assets/svg/idfc.jpeg";
import bajaj from "../assets/svg/bajaj.png";
import pnb from "../assets/svg/Pnb-Housing-Finance-Logo-PNG.webp"
import multiple from "../assets/svg/Multiple-Offers.jpeg";
import assistance from "../assets/svg/get-assistance.png";
import tracking from "../assets/svg/application.png";
import customer from "../assets/svg/customer-support.jpeg"
import loan from "../assets/svg/loanimg.png"
import { useEffect } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import {FaPhone } from "react-icons/fa";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Prop from "../assets/svg/Prop.jpeg";
import SocialIcon from '../components/SocialIcon';
const Emi = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <div className='display-copy'>
          <FloatingWhatsApp accountName='Property10X' phoneNumber='9871424020' avatar={Prop} statusMessage='Replies within a minute' 
                        chatMessage='Welcome to Property10X'
                        allowClickAway={true} />
    <div className="home-loan-header">
    <h1>Home Loan  <p className=''>"Securing a home loan is the first step towards building a foundation of wealth, providing both financial stability and emotional security."</p></h1>
  </div>
  <div class="container Emicont">
  <div class="left emileft">
    <h1 className='emih1'>Choose us for your home loan needs.<p className=' emipp text-center lg:text-left ml-3 font-light text-black '>Our top-notch mortgage services make your dream home a reality, as we partner with reputed banks to offer you swift and simple housing loans at low-interest rates and reduced EMIs - all in the pursuit of making your significant investment in your dream house a reality.</p></h1>
  </div>
  <div class="right emiright">
    <form className='emiform'>
      <div class="form-group emigroup">
        <label for="fullName"></label>
        <input type="text" id="fullName" name="fullName" placeholder="Enter your full name"  required />
      </div>
      <div class="form-group">
        <label for="email"></label>
        <input type="email" id="email" name="email" placeholder="Enter your Email-Id"  required />
      </div>
      <div class="form-group">
        <label for="contactNo"></label>
        <input type="tel" id="contactNo" name="contactNo" placeholder="Enter your Contact No."  required />
      </div>
      <div class="form-group">
        <label for="address"></label>
        <textarea id="address" name="address" placeholder="Enter your Address" required></textarea>
      </div>
      <div class="form-group">
        <label for="occupation"></label>
        <select id="occupation" name="occupation"  required>
          <option value="">--Occupation--</option>
          <option value="salaried">Salaried</option>
          <option value="business">Business</option>
        </select>
      </div>
      <div class="form-group">
        <label for="annualIncome"></label>
        <input type="number" id="annualIncome" name="annualIncome" placeholder="Annual Income (in lacs)"  required />
      </div>
      <div class="form-group">
        <label for="loanAmount"></label>
        <input type="number" id="loanAmount" name="loanAmount" placeholder="Loan Amount Required (in lacs)"  required />
      </div>
      <div class="form-group">
        <label for="currentEmi"></label>
        <select id="currentEmi" name="currentEmi">
        <option value="">--Current EMI--</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      <button className='emibut' type="submit">Get instant Call</button>
    </form>
  </div>
</div>
<div><h2 className='sp'>Home Loan Offers</h2></div>
<div>
  <table>
    <thead>
      <tr>
        <th>Bank Name</th>
        <th>Interest Rates</th>
        <th>Processing Rate</th>
        <th>Tenure</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><img src={tata} alt="Bank A Logo" width="100" height="50" /></td>
        <td>8.1% - 9.05%</td>
        <td>Up to 0.5%</td>
        <td>₹5L - ₹10Cr
Tenure(Up to 30 Years)</td>
      </tr>
      <tr>
        <td><img src={idfc} alt="Bank B Logo" width="100" height="50" /></td>
        <td>8.1% - 9.05%</td>
        <td>Up to 0.5%</td>
        <td>₹5L - ₹10Cr
Tenure(Up to 30 Years)</td>
      </tr>
      <tr>
        <td><img src={bajaj} alt="Bank C Logo" width="100" height="50" /></td>
        <td>8.1% - 9.05%</td>
        <td>Up to 0.5%</td>
        <td>₹5L - ₹10Cr
Tenure(Up to 30 Years)</td>
      </tr>
      <tr>
        <td><img src={pnb} alt="Bank D Logo" width="100" height="50" /></td>
        <td>8.1% - 9.05%</td>
        <td>Up to 0.5%</td>
        <td>₹5L - ₹10Cr
Tenure(Up to 30 Years)</td>
      </tr>
    </tbody>
  </table>
</div>
<div><h2 className='sp'>Apply with us and find out how we can help you overcome them.
</h2><p className='lg:text-center px-4'>Get the Best Home Loan Interest Rates from India's Leading Banks
</p></div>
<div class="card-container">
  <div class="card">
    <img src={multiple} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Multiple Offers</h3>
      <p>Get offers from multiple banks and choose the home loan that works best for you.</p>
    </div>
  </div>
  <div class="card">
    <img src={assistance} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Get Assistance</h3>
      <p>Our team of experts is always available to answer all your home loan queries and guide you throughout the process.</p>
    </div>
  </div>
  <div class="card">
    <img src={tracking} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Application Tracking</h3>
      <p>On the Property10X platform, you can eazily track your application status online from anywhere, anytime.</p>
    </div>
  </div>
  <div class="card">
    <img src={customer} alt="Card Image"/>
    <div class="card-content">
      <h3 className='text-[#005978] font-semibold'>Customer Support</h3>
      <p>Customer Support is a vital aspect of any business that deals with customers and It involves providing assistance.</p>
    </div>
  </div>
</div>
<div><h2 className='sp'>Check your Loan Eligibility</h2></div>
<div className="container emical">
  <div className="image-container emiim">
    <img src={loan} alt="Image description" />
  </div>
  <div className="content-container emicon">
    <h2>Check your Loan Eligibility</h2>
    <p>Check your loan eligibility by contacting your lender and providing them with the necessary information.
Lenders typically consider factors such as credit score, income, and debt-to-income ratio when determining loan eligibility.</p>
<button onClick={() => window.location.href='/sign-in'}>Check Here</button>

  </div>
</div>
<div className=' text-center mt-10 text-[#005978] font-bold text-3xl'>Connect with us</div>
    <SocialIcon />
  </div>
  )
}

export default Emi;