import React, { useState, useEffect } from 'react';

const PossessionStatus = ({ onValidationChange = () => {} }) => {
  const [possessionStatus, setPossessionStatus] = useState('');
  const [availableYear, setAvailableYear] = useState('');
  const [availableMonth, setAvailableMonth] = useState('');

  // useEffect(() => {
  //   if (possessionStatus === 'Under Construction' && (!availableMonth || !availableYear)) {
  //     onValidationChange(false);
  //   } else {
  //     onValidationChange(true);
  //   }
  // }, [possessionStatus, availableMonth, availableYear]);

  return (
    <div className="gap-4">
      <div className="gap-8 pb-8">
        <div className="gap-10 flex flex-wrap">
          <p className="text-gray-600">Possession Status</p>
          <div className="flex items-center space-x-4">
            <label htmlFor="possessionStatusUnderConstruction">
              <input
                type="radio"
                id="possessionStatusUnderConstruction"
                name="possessionStatus"
                value="Under Construction"
                checked={possessionStatus === 'Under Construction'}
                onChange={() => setPossessionStatus('Under Construction')}
                className="mr-2"
              />
              Under Construction
            </label>
            <label htmlFor="possessionStatusReadyToMove">
              <input
                type="radio"
                id="possessionStatusReadyToMove"
                name="possessionStatus"
                value="Ready to Move"
                checked={possessionStatus === 'Ready to Move'}
                onChange={() => {
                  setPossessionStatus('Ready to Move');
                  setAvailableMonth('');
                  setAvailableYear('');
                }}
                className="mr-2"
              />
              Ready to Move
            </label>
          </div>
        </div>

        {/* Available From Dropdown (Only show when Under Construction is selected) */}
        {possessionStatus === 'Under Construction' && (
          <div className="flex flex-col mt-4">
            <label className="text-gray-600 mb-2">Available From</label>
            <div className="flex space-x-4">
              {/* Month Dropdown */}
              <select
                value={availableMonth}
                onChange={(e) => setAvailableMonth(e.target.value)}
                className="border p-2 rounded"
              >
                <option value="">Select Month</option>
                {[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              {/* Year Dropdown */}
              <select
                value={availableYear}
                onChange={(e) => setAvailableYear(e.target.value)}
                className="border p-2 rounded"
              >
                <option value="">Select Year</option>
                {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PossessionStatus;




// const ParentForm = () => {
//   const [isValid, setIsValid] = useState(true);

//   const handleValidationChange = (status) => {
//     setIsValid(status);
//   };

//   return (
//     <form onSubmit={(e) => {
//       e.preventDefault();
//       if (!isValid) {
//         alert("Please fill in the required fields.");
//         return;
//       }
//       alert("Form submitted!");
//     }}>
//       <PossessionStatus onValidationChange={handleValidationChange} />
//       <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
//         Submit
//       </button>
//     </form>
//   );
// };
