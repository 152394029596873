import React, { useState } from "react";

const FacingMainRoad = () => {
  const [isFacingRoad, setIsFacingRoad] = useState("");
  return (
    <div className="gap-10 mt-4">
      <div className=" gap-8 mt-4  flex flex-wrap">
        <p className="text-gray-600">Is facing main Road?</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="isFacingRoadYes">
            <input
              type="radio"
              id="isFacingRoadYes"
              name="isFacingRoad"
              value={true}
              checked={isFacingRoad}
              onChange={() => setIsFacingRoad(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="isFacingRoadNo">
            <input
              type="radio"
              id="isFacingRoadNo"
              name="isFacingRoad"
              value={false}
              checked={!isFacingRoad}
              onChange={() => setIsFacingRoad(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default FacingMainRoad;
