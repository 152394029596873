import React, { useState } from 'react'

const Price = () => {
    const [priceNegotiable, setPriceNegotiable] = useState('');
  return (
    <div className='mt-4'>
      <h1 className="text-lg font-semibold mb-2 mt-4">Price Details</h1>

<div className="grid grid-cols-2 gap-4">
  <div>
    <label htmlFor="expectedPrice" className="block mb-1">
      Expected Price
    </label>
    <div className="flex items-center">
    <span className="ml-2 pr-3">₹</span>
      <input
        type="number"
        id="expectedPrice"
        className="border border-gray-300 rounded-md p-2 w-20"
        // disabled // Make this field read-only
      />
     
    </div>
  </div>
  <div>
    <label htmlFor="bookingAmount" className="block mb-1">
      Booking/Token Amount (optional)
    </label>
    <div className="flex items-center">
      <input
        type="number"
        id="bookingAmount"
        className="border border-gray-300 rounded-md p-2 w-20"
      />
      <span className="ml-2">₹</span>
    </div>
  </div>
</div>

<div className="mt-4">
  <label htmlFor="priceNegotiable">
    <input
      type="checkbox"
      id="priceNegotiable"
      checked={priceNegotiable}
      onChange={() => setPriceNegotiable(!priceNegotiable)}
      className="mr-2"
    />
    Price Negotiable
  </label>
</div>

{priceNegotiable && (
  <p className="text-gray-600 mt-2">
    You will receive Offers from Buyers in range nearby to your expected price!!
  </p>
)}
    </div>
  )
}

export default Price
