import React from "react";
import "./PropertyFeatures.css";
import PhotoUpload from "./inputcomponents/Photos";
import GatedColony from "./inputcomponents/GatedColony";
import ConstructionDone from "./inputcomponents/ConstructionDone";
import BoundaryWall from "./inputcomponents/BoundaryWall";
import RoadWidth from "./inputcomponents/RoadWidth";
import OpenSides from "./inputcomponents/OpenSides";
import Price from "./inputcomponents/Price";
import AreaLabel from "./labels/AreaLabel";
import PlotArea from "./inputcomponents/PlotArea";
import PlotDimensions from "./inputcomponents/PlotDimensions";
import CornerPlot from "./inputcomponents/CornerPlot";
import PropertyLocation from "./inputcomponents/PropertyLocation";

const ResidentialLand = () => {

  return (
    <div className="mb-4">
      <PropertyLocation />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>
      <div className="gap-4">
        <OpenSides />
        <RoadWidth />
        <ConstructionDone />
        <BoundaryWall />
        <GatedColony />
      </div>

      <div className="mt-4">
        <AreaLabel />
        <PlotArea />
        <PlotDimensions />
        <CornerPlot />
      </div>

      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  );
};

export default ResidentialLand;
