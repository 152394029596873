import React from "react";
import "./PropertyFeatures.css";
import PhotoUpload from "./inputcomponents/Photos";
import BedRooms from "./inputcomponents/BedRooms";
import Balconies from "./inputcomponents/Balconies";
import FurnishedStatus from "./inputcomponents/FurnishedStatus";
import BathRooms from "./inputcomponents/BathRooms";
import OpenSides from "./inputcomponents/OpenSides";
import RoadWidth from "./inputcomponents/RoadWidth";
import AreaLabel from "./labels/AreaLabel";
import PlotArea from "./inputcomponents/PlotArea";
import CornerPlot from "./inputcomponents/CornerPlot";
import SuperArea from "./inputcomponents/SuperArea";
import PossessionStatus from "./inputcomponents/PossessionStatus";
import Price from "./inputcomponents/Price";
import TotalFloors from "./inputcomponents/TotalFloors";
import ResidentialLocation from "./inputcomponents/ResidentialLocation";

const ResidentialHouse = () => {

  return (
    <div className="mb-4">
      <ResidentialLocation />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>

      <div className="grid grid-cols-2 gap-4">
        <BedRooms />
        <Balconies />
        <TotalFloors />
        <FurnishedStatus />
        <BathRooms />
        <OpenSides />
        <RoadWidth />
      </div>

      <div className="mb-4">
        <AreaLabel />
        <PlotArea />
        <CornerPlot />
        <SuperArea />
      </div>

      <div>
        <h1 className="text-lg font-semibold mb-2 mt-4">
          Transaction Type, Property Availability
        </h1>
        <PossessionStatus />
      </div>

      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  );
};

export default ResidentialHouse;
