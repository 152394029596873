import React, { useState } from "react";

const CornerShop = () => {
  const [isCornerShop, setIsCornerShop] = useState("");
  return (
    <div className="gap-10 mt-4">
      <div className=" gap-8 mt-4  flex flex-wrap">
        <p className="text-gray-600">Corner Shop</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="isCornerShopYes">
            <input
              type="radio"
              id="isCornerShopYes"
              name="isCornerShop"
              value={true}
              checked={isCornerShop}
              onChange={() => setIsCornerShop(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="isCornerShopNo">
            <input
              type="radio"
              id="isCornerShopNo"
              name="isCornerShop"
              value={false}
              checked={!isCornerShop}
              onChange={() => setIsCornerShop(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default CornerShop;
