import React, { useState } from 'react'

const CarpetArea = () => {
    const [carpetArea, setCarpetArea ] = useState('');
    const areaUnits = ['Sq-ft', 'Sq-yards', 'Acres', 'Hectares', 'Sq-meters']; // Add more as needed
    const [carpetAreaUnit, setCarpetAreaUnit ] = useState('');
  return (
    <div className="grid w-1/3 gap-4">
    <div>
      <label htmlFor="carpetArea" className="block mb-1">
      Carpet Area
      </label>
      <div className="flex items-center">
        <input
          type="number"
          id="carpetArea"
          value={carpetArea}
          onChange={(e) => setCarpetArea(e.target.value)}
          required
          className="border border-gray-300 rounded-md p-2 w-20" // Added width for better alignment
        />
        <select
          value={carpetAreaUnit}
          onChange={(e) => setCarpetAreaUnit(e.target.value)}
          className="border border-gray-300 rounded-md p-2 ml-2" // Added margin-left
        >
          {areaUnits.map((unit) => (
            <option key={unit} value={unit}>
              {unit}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
  )
}

export default CarpetArea
