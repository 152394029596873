import React, { useState } from 'react'

const CoverageArea = () => {
    const [coverageArea, setCoverageArea ] = useState('');
    const areaUnits = ['Sq-ft', 'Sq-yards', 'Acres', 'Hectares', 'Sq-meters']; // Add more as needed
    const [coverageAreaUnit, setCoverageAreaUnit ] = useState('');
  return (
    <div className="grid w-1/3 gap-4">
        <div>
          <label htmlFor="coverageArea" className="block mb-1">
          Covered Area
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="coverageArea"
              value={coverageArea}
              onChange={(e) => setCoverageArea(e.target.value)}
              required
              className="border border-gray-300 rounded-md p-2 w-20" // Added width for better alignment
            />
            <select
              value={coverageAreaUnit}
              onChange={(e) => setCoverageAreaUnit(e.target.value)}
              className="border border-gray-300 rounded-md p-2 ml-2" // Added margin-left
            >
              {areaUnits.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    
  )
}

export default CoverageArea
