import React, { useState } from 'react'

const SuperArea = () => {
    
const [superArea, setSuperArea] = useState('');
const areaUnits = ['Sq-ft', 'Sq-yards', 'Acres', 'Hectares', 'Sq-meters']; // Add more as needed
const [superAreaUnit, setSuperAreaUnit] = useState('Sq-ft');
  return (
    <div className="grid w-1/3 gap-4 mt-4">
        <div>
          <label htmlFor="superArea" className="block mb-1">
            Super Area
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="superArea"
              value={superArea}
              onChange={(e) => setSuperArea(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-20" // Added width
            />
            <select
              value={superAreaUnit}
              onChange={(e) => setSuperAreaUnit(e.target.value)}
              className="border border-gray-300 rounded-md p-2 ml-2"
            >
              {areaUnits.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
            <span className="text-xs w-full text-gray-500 ml-2">(Optional)</span> {/* Moved optional text */}
          </div>
        </div>
      </div>
  )
}

export default SuperArea
