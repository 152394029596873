import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import "./SellForm.css";

const firebaseConfig = {
  apiKey: "AIzaSyCGAExQE3mFIPKKo2Q6CM61Os1S6KlDoSM",
  authDomain: "property10x-1.firebaseapp.com",
  projectId: "property10x-1",
  storageBucket: "property10x-1.appspot.com",
  messagingSenderId: "98752474545",
  appId: "1:98752474545:web:4c1d14659003c11eeba190",
  measurementId: "G-8Y25D7LZP5",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const SellForm = () => {
  const [formData, setFormData] = useState({
    userType: "",
    sellerName: "",
    contactNumber: "",
    email: "",
    propertyType: "",
    location: "",
    purpose: "",
    size: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "properties"), formData);
      alert("Property submitted successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="mt-20 m-2 mb-6  text-center blinking-text">
                <h2 className="px-3 text-4xl mt-6 font-semibold">
                  Coming soon...
                </h2>
              </div>
    // <Box display="flex" sx={{ padding: 3 }}>
    //   {/* Form Section */}
    //   <Box
    //     component="form"
    //     onSubmit={handleSubmit}
    //     sx={{ flex: 2, marginRight: 3 }}
    //   >
    //     {/* Header */}
    //     <Typography variant="h4" className="highlight" gutterBottom>
    //       Sell Your Property Here
    //     </Typography>
    //     <Typography variant="subtitle1" color="textSecondary" gutterBottom>
    //       Absolutely Free!
    //     </Typography>

    //     <Divider sx={{ marginY: 2 }} />

    //     {/* Personal Details */}
    //     <Typography variant="h6" gutterBottom>
    //       Personal Details
    //     </Typography>
    //     <TextField
    //       select
    //       name="userType"
    //       label="I am"
    //       value={formData.userType}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     >
    //       <MenuItem value="owner">Owner</MenuItem>
    //       <MenuItem value="agent">Agent</MenuItem>
    //       <MenuItem value="builder">Builder</MenuItem>
    //     </TextField>
    //     <TextField
    //       name="sellerName"
    //       label="Name"
    //       value={formData.sellerName}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     />
    //     <TextField
    //       name="contactNumber"
    //       label="Mobile Number"
    //       value={formData.contactNumber}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     />
    //     <TextField
    //       name="email"
    //       label="Email Address"
    //       value={formData.email}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     />

    //     <Divider sx={{ marginY: 2 }} />

    //     {/* Property Details */}
    //     <Typography variant="h6" gutterBottom>
    //       Property Details
    //     </Typography>
    //     <TextField
    //       select
    //       name="propertyType"
    //       label="Property Type"
    //       value={formData.propertyType}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     >
    //       <MenuItem value="flats">Flats/Apartments</MenuItem>
    //       <MenuItem value="industrial">Industrial Land</MenuItem>
    //     </TextField>
    //     <TextField
    //       name="location"
    //       label="Property Location"
    //       value={formData.location}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     />
    //     <TextField
    //       select
    //       name="purpose"
    //       label="Purpose"
    //       value={formData.purpose}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     >
    //       <MenuItem value="sale">For Sale</MenuItem>
    //       <MenuItem value="rent">For Rent</MenuItem>
    //       <MenuItem value="lease">For Lease</MenuItem>
    //       <MenuItem value="pg">PG</MenuItem>
    //     </TextField>
    //     <TextField
    //       name="size"
    //       label="Coverage Area (in sq mt)"
    //       type="number"
    //       value={formData.size}
    //       onChange={handleChange}
    //       fullWidth
    //       margin="normal"
    //       required
    //     />

    //     <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
    //       Submit
    //     </Button>
    //   </Box>

    //   {/* Advertisement Section */}
    //   <Box sx={{ flex: 1, backgroundColor: "#f5f5f5", padding: 2, borderRadius: 2 }}>
    //     <Typography variant="h6" gutterBottom>
    //       Advertisement
    //     </Typography>
    //     <Typography variant="body2" color="textSecondary">
    //       Place your ads here. Promote your business to millions of potential clients.
    //     </Typography>
    //     {/* Add your advertisement component or content here */}
    //   </Box>
    // </Box>
  );
};

export default SellForm;
