import React, { useState } from 'react'

const Balconies = () => {
  const [balconies, setBalconies] = useState("");
  return (
    <div>
    <label htmlFor="balconies" className="block mb-1">
      Balconies:
    </label>
    <select
      id="balconies"
      value={balconies}
      onChange={(e) => setBalconies(e.target.value)}
      required
      className="border border-gray-300 rounded-md p-2"
    >
      <option value="default">Select an Option</option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="3+">3+</option>
    </select>
  </div>
  )
}

export default Balconies
