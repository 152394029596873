import React, { useState } from 'react'

const AgeOfConstruction = () => {
    const [ageOfConstruction, setAgeOfConstruction] = useState('Less than 5 years');
  return (
    <div>
    <label htmlFor="ageOfConstruction" className="block mb-1">
      Age of Construction
    </label>
    <select
      id="ageOfConstruction"
      value={ageOfConstruction}
      onChange={(e) => setAgeOfConstruction(e.target.value)}
      required
      className="border border-gray-300 rounded-md p-2"
    >
      <option value="Less than 5 years">Less than 5 years</option>
      {/* Add more options as needed */}
    </select>
  </div>
  )
}

export default AgeOfConstruction
