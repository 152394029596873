import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube ,FaPhone} from 'react-icons/fa';
import "./SocialIcons.css";
import { FaSquareThreads, FaSquareXTwitter } from 'react-icons/fa6';
function SociaIcons() {
  return (
    <div className="social-icons-container">
          <a href="tel:9871424020">
    <i className="FaPhone"></i>
    <FaPhone />
  </a>
      <a href="https://www.facebook.com/theproperty10x?mibextid=LQQJ4d" className="social-icon" target="_blank" rel="noreferrer"><FaFacebook /></a>
      <a href="https://www.instagram.com/the_property10x?igsh=MTZhMDF5anFicTByZQ%3D%3D&utm_source=qr" className="social-icon" target="_blank" rel="noreferrer"><FaInstagram /></a>
      <a href="https://www.linkedin.com/company/property-10x/" className="social-icon" target="_blank" rel="noreferrer"><FaLinkedin /></a>
      <a href="https://www.youtube.com/@property10x" className="social-icon" target="_blank" rel="noreferrer"><FaYoutube /></a>
      <a href="https://x.com/property10x" className="social-icon" target="_blank" rel="noreferrer"><FaSquareXTwitter /></a>
      <a href=" https://www.threads.net/@the_property10x?igshid=NTc4MTIwNjQ2YQ== " target="_blank" rel="noreferrer"><FaSquareThreads /> </a>
    </div>
  );
}

export default SociaIcons;
