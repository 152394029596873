import React, { useState } from "react";

const CornerShowroom = () => {
  const [isCornerShowroom, setIsCornerShowroom] = useState("");
  return (
    <div className="gap-10 mt-4">
      <div className=" gap-8 mt-4  flex flex-wrap">
        <p className="text-gray-600">Corner Showroom</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="isCornerShowroomYes">
            <input
              type="radio"
              id="isCornerShowroomYes"
              name="isCornerShowroom"
              value={true}
              checked={isCornerShowroom}
              onChange={() => setIsCornerShowroom(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="isCornerShowroomNo">
            <input
              type="radio"
              id="isCornerShowroomNo"
              name="isCornerShowroom"
              value={false}
              checked={!isCornerShowroom}
              onChange={() => setIsCornerShowroom(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default CornerShowroom;
