import React, { useState } from 'react'

const RoadWidth = () => {
  const [roadWidth, setRoadWidth] = useState(''); 
  return (
    <div>
       <div>
          <label htmlFor="roadWidth" className="block mb-4">
            Width of road facing the plot:
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="roadWidth"
              value={roadWidth}
              onChange={(e) => setRoadWidth(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-20 mb-4"
            />
            <span className="ml-2">Meters</span>
          </div>
        </div>
    </div>
  )
}

export default RoadWidth
