import React from "react";
import "../PropertyFeatures.css";
import FacingMainRoad from "../inputcomponents/FacingMainRoad";
import PersonalWashroom from "../inputcomponents/PersonalWashroom";
import PantryCafeteria from "../inputcomponents/PantryCafeteria";
import FloorNo from "../inputcomponents/FloorNo";
import TotalFloors from "../inputcomponents/TotalFloors";
import FurnishedStatus from "../inputcomponents/FurnishedStatus";
import AreaLabel from "../labels/AreaLabel";
import PlotArea from "../inputcomponents/PlotArea";
import CarpetArea from "../inputcomponents/CarpetArea";
import CoverageArea from "../inputcomponents/CoverageArea";
import PossessionStatus from "../inputcomponents/PossessionStatus";
import Price from "../inputcomponents/Price";
import PhotoUpload from "../inputcomponents/Photos";
import LeaseStatus from "../inputcomponents/LeaseStatus";
import AssuredReturns from "../inputcomponents/AssuredReturns";
import CornerShowroom from "../inputcomponents/CornerShowroom";
import WidthofEntrance from "../inputcomponents/WidthofEntrance";
import PropertyLocation from "../inputcomponents/PropertyLocation";
import LandZone from "../inputcomponents/LandZone";
import IdealForBusiness from "../inputcomponents/IdealForBusiness";

const CommercialShowroom = () => {
  return (
    <div className="mb-4">
      <PropertyLocation />
      <LandZone />
      <IdealForBusiness />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>

      <div className="grid grid-cols-2 gap-4">
        <FloorNo />
        <TotalFloors />
        <FurnishedStatus />
      </div>
      <div className="gap-4 mb-4">
        <CornerShowroom />
        <FacingMainRoad />
        <PersonalWashroom />
        <PantryCafeteria />
      </div>

      <div className="mb-4">
        <AreaLabel />
        <CoverageArea />
        <PlotArea />
        <CarpetArea />
        <WidthofEntrance />
      </div>

      <div>
        <h1 className="text-lg font-semibold mb-2 mt-4">
          Transaction Type, Property Availability
        </h1>
        <PossessionStatus />
        <LeaseStatus />
        <AssuredReturns />
      </div>
      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  );
};

export default CommercialShowroom;
