import React, { useState } from 'react'

const PlotArea = () => {
  
const [plotArea, setPlotArea] = useState('');
const areaUnits = ['Sq-ft', 'Sq-yards', 'Acres', 'Hectares', 'Sq-meters']; // Add more as needed
const [plotAreaUnit, setPlotAreaUnit] = useState('Sq-ft');
  return (
    <div className="grid w-1/3 gap-4">
        <div>
          <label htmlFor="plotArea" className="block mb-1">
            Plot Area
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="plotArea"
              value={plotArea}
              onChange={(e) => setPlotArea(e.target.value)}
              required
              className="border border-gray-300 rounded-md p-2 w-20" // Added width for better alignment
            />
            <select
              value={plotAreaUnit}
              onChange={(e) => setPlotAreaUnit(e.target.value)}
              className="border border-gray-300 rounded-md p-2 ml-2" // Added margin-left
            >
              {areaUnits.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    
  )
}

export default PlotArea
