// import React from "react";
// import Item from "./Item.js";

// import {RESOURCES, COMPANY, SUPPORT ,ADDRESS} from "./Menus.js";
// const ItemsContainer = () => {
//   return (
//     <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16">
//       <Item Links={COMPANY} title="COMPANY" />
//       <Item Links={SUPPORT} title="SUPPORT" />
//       <Item Links={RESOURCES} title="RESOURCES" />
//       <Item Links={ADDRESS} title="ADDRESS" />
// </div>
//   );
// };
// export default ItemsContainer;

import React from "react";
const COMPANY = [
  { name: "☎️ Contact us", link: "/contactus" },
  { name: "🆎 About us", link: "/aboutus" },
  { name: "📩 News", link: "https://realty.economictimes.indiatimes.com/" },
  { name: "📑 Customer Stories", link: "#" },
  { name: "💬 Online communities", link: "#" },
];

const SUPPORT = [
  {
    name: "📩 support@theproperty10x.com",
    link: "mailto:support@theproperty10x.com",
  },
  { name: "📞 +91-9871424020", link: "tel:+919871424020" },
  { name: "🫙 Terms & Conditions", link: "/term" },
  { name: "🔓 Privacy Policy", link: "/privacy" },
  { name: "📮 Feedback", link: "/feedback" },
];

const RESOURCES = [
  { name: "Industries and tools", link: "#" },
  { name: "Use cases", link: "#" },
  { name: "Blog", link: "#" },
  { name: "Online event", link: "#" },
];

const ADDRESS = [
  // {name:"Sector-2,Noida, Gautam Buddha Nagar, (Uttar Pradesh), 201301"},
  {
    name: "Branch Office- Tower-4, Office-719,x Assotech business cresterra- 201304, Sector-135, Noida (Uttar Pradesh), India",
    link: "https://www.google.com/maps/dir//Assotech+Business+Cresterra+Plot+No+22+Sector+135+Noida,+Uttar+Pradesh+201304/@28.4963031,77.4018702,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390ce860bfea95d9:0x683ddf48f263ecc!2m2!1d77.4018702!2d28.4963031?entry=ttu&g_ep=EgoyMDI1MDIwNS4xIKXMDSoASAFQAw%3D%3D",
  },
];

export const Icons = [
  {
    name: "logo-facebook",
    link: "https://www.facebook.com/theproperty10x?mibextid=LQQJ4d",
  },
  { name: "logo-twitter", link: "#" },
  {
    name: "logo-linkedin",
    link: "https://www.linkedin.com/company/property-10x/",
  },
  {
    name: "logo-instagram",
    link: "https://www.instagram.com/the_property10x?igsh=MTZhMDF5anFicTByZQ%3D%3D&utm_source=qr",
  },
];

const Item = ({ Links, title }) => {
  return (
    <ul>
      <h2 className="mb-1 font-Poppins,sans-serif font-bold">{title}</h2>
      {Links.map((link) => (
        <li key={link.name}>
          <a
            className="text-gray-300 hover:text-teal-400 duration-300 text-sm cursor-pointer leading-6"
            href={link.link}
          >
            {link.name}
          </a>
        </li>
      ))}
    </ul>
  );
};
const ItemsContainer = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16">
      <Item Links={COMPANY} title="COMPANY" />
      <Item Links={SUPPORT} title="SUPPORT" />
      <Item Links={RESOURCES} title="RESOURCES" />
      <Item Links={ADDRESS} title="ADDRESS" />
    </div>
  );
};

export default ItemsContainer;
