import React from 'react';
import './PropertyFeatures.css';
import PhotoUpload from './inputcomponents/Photos';
import BedRooms from './inputcomponents/BedRooms';
import Balconies from './inputcomponents/Balconies';
import TotalFloors from './inputcomponents/TotalFloors';
import FurnishedStatus from './inputcomponents/FurnishedStatus';
import BathRooms from './inputcomponents/BathRooms';
import FloorsAllowed from './inputcomponents/FloorsAllowed';
import OpenSides from './inputcomponents/OpenSides';
import RoadWidth from './inputcomponents/RoadWidth';
import AreaLabel from './labels/AreaLabel';
import PlotArea from './inputcomponents/PlotArea';
import CornerPlot from './inputcomponents/CornerPlot';
import SuperArea from './inputcomponents/SuperArea';
import PossessionStatus from './inputcomponents/PossessionStatus';
import Price from './inputcomponents/Price';
import PlotDimensions from './inputcomponents/PlotDimensions';
import CarpetArea from './inputcomponents/CarpetArea';
import PropertyLocation from './inputcomponents/PropertyLocation';

const Villa = () => {

  return (
    <div className="mb-4">
      <PropertyLocation />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>

      <div className="grid grid-cols-2 gap-4">
      <BedRooms />
        <Balconies />
        <TotalFloors />
        <FurnishedStatus />
        <BathRooms />
        <FloorsAllowed />
        <OpenSides/> 
        <RoadWidth />
      </div>


      <div className="mb-4">
        <AreaLabel />
        <PlotArea />
        <PlotDimensions />
        <CornerPlot />
        <CarpetArea />
        <SuperArea />
      </div>

      <div>
        <h1 className="text-lg font-semibold mb-2 mt-4">
          Transaction Type, Property Availability
        </h1>
        <PossessionStatus />
      </div>
      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  );
};

export default Villa;