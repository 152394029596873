import React from 'react'
import FloorNo from '../inputcomponents/FloorNo'
import TotalFloors from '../inputcomponents/TotalFloors'
import FurnishedStatus from '../inputcomponents/FurnishedStatus'
import BathRooms from '../inputcomponents/BathRooms'
import PersonalWashroom from '../inputcomponents/PersonalWashroom'
import PantryCafeteria from '../inputcomponents/PantryCafeteria'
import CarpetArea from '../inputcomponents/CarpetArea'
import SuperArea from '../inputcomponents/SuperArea'
import AreaLabel from '../labels/AreaLabel'
import PossessionStatus from '../inputcomponents/PossessionStatus'
import LeaseStatus from '../inputcomponents/LeaseStatus'
import AssuredReturns from '../inputcomponents/AssuredReturns'
import Price from '../inputcomponents/Price'
import PhotoUpload from '../inputcomponents/Photos'
import PropertyLocation from '../inputcomponents/PropertyLocation'
import LandZone from '../inputcomponents/LandZone'
import IdealForBusiness from '../inputcomponents/IdealForBusiness'

const OfficeSpace = () => {
  return (
    <div className="mb-4">
        <PropertyLocation />
        <LandZone />
        <IdealForBusiness />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>

      <div className="grid grid-cols-2 gap-4">
        <FloorNo />
        <TotalFloors />
        <FurnishedStatus />
        <BathRooms />
        </div>
        <div className="gap-4 mb-4">
        <PersonalWashroom />
        <PantryCafeteria />
      </div>

      <div className="mt-4">
        <AreaLabel />
        <CarpetArea />
        <SuperArea />
      </div>
      
      <div className=" gap-4">
        <h1 className="text-lg font-semibold mb-2 mt-4">
          Transaction Type, Property Availability
        </h1>
          <PossessionStatus />
          <LeaseStatus />
          <AssuredReturns />
      </div>
    
      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  )
}

export default OfficeSpace
