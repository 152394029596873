import React, { useState } from 'react'

const PersonalWashroom = () => {
  const [personalWashroom, setPersonalWashroom] = useState('');
  return (
    <div className='gap-10 mt-4'>
        <div className=" gap-8 mt-4  flex flex-wrap">
        <p className="text-gray-600">Personal Washroom</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="PersonalWashroomYes">
            <input
              type="radio"
              id="personalWashroomYes"
              name="personalWashroom"
              value={true}
              checked={personalWashroom}
              onChange={() => setPersonalWashroom(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="PersonalWashroomNo">
            <input
              type="radio"
              id="personalWashroomNo"
              name="personalWashroom"
              value={false}
              checked={!personalWashroom}
              onChange={() => setPersonalWashroom(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  )
}

export default PersonalWashroom
