import React from 'react'
import "../pages/Aboutus.css";
import { BsFacebook } from 'react-icons/bs'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaThreads, FaXTwitter } from 'react-icons/fa6'
import { FiInstagram } from 'react-icons/fi'

const SocialIcon = () => {
  return (
    <div className='soc'>
      <div className="social-media">
              <a
                href=" https://www.threads.net/@the_property10x?igshid=NTc4MTIwNjQ2YQ== "
                target="_blank"
              >
                <i className="FaThreads"></i>
                <FaThreads />
              </a>
              <a
                href=" https://www.linkedin.com/company/property-10x/"
                target="_blank"
              >
                <i className="FaLinkedinIn"></i>
                <FaLinkedinIn />
              </a>
              <a
                href=" https://www.instagram.com/the_property10x?igsh=MTZhMDF5anFicTByZQ%3D%3D&utm_source=qr "
                target="_blank"
              >
                <i className="FiInstagram"></i>
                <FiInstagram />
              </a>
              <a
                href=" https://www.facebook.com/theproperty10x?mibextid=LQQJ4d"
                target="_blank"
              >
                <i className="BsFacebook"></i>
                <BsFacebook />
              </a>
              <a href=" https://x.com/property10x " target="_blank">
                <i className="FaXTwitter"></i>
                <FaXTwitter />
              </a>
            </div>
          
    </div>
  )
}

export default SocialIcon
