import React, { useState } from 'react'

const PlotDimensions = () => {
const [plotLength, setPlotLength] = useState('');
const [plotBreadth, setPlotBreadth] = useState('');
  return (
    <div>
      <div className="grid w-1/3 gap-4 mt-4">
        <div>
          <label htmlFor="plotLength" className="block mb-1">
            Plot Length
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="plotLength"
              value={plotLength}
              onChange={(e) => setPlotLength(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-20" // Added width
            />
            <span className="text-xs w-full text-gray-500 ml-2">ft</span> {/* Moved optional text */}
          </div>
        </div>
      </div>

      <div className="grid w-1/3 gap-4 mt-4">
        <div>
          <label htmlFor="plotBreadth" className="block mb-1">
            Plot Breadth
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="plotBreadth"
              value={plotBreadth}
              onChange={(e) => setPlotBreadth(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-20" // Added width
            />
            <span className="text-xs w-full text-gray-500 ml-2">ft</span> {/* Moved optional text */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlotDimensions
