import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import houses from "../assets/svg/lanscape.jpeg";
import samplevideo from "../assets/svg/samplevideo.mp4";
import oasis from "../assets/svg/oasis.jpg";
import dasnac from "../assets/svg/dasnac.jpg";
import house3 from "../assets/svg/des.jpeg";
import house4 from "../assets/svg/desseller.png";
import './HeroSlider.css';

const HeroSlider = () => {
  const heroItems = [
    // Array to hold images and/or video objects
    { type: "image", src: oasis },
    { type: "image", src: dasnac },
    { type: "image", src: house3 },
    { type: "image", src: houses},
    // { type: "image", src: house4 },
    // { type: "video", src: samplevideo }, 
  ];
  const [currentHeroIndex, setCurrentHeroIndex] = useState(0);
  const videoRef = useRef(null);
  const animationRef = useRef(null); // Ref for requestAnimationFrame
  const containerRef = useRef(null); // Ref for the container
  const heroRef = useRef(null); // Ref for the hero container
  const itemWidthRef = useRef(0); // Ref to store width of each item

  useEffect(() => {
    const calculateItemWidth = () => {
      if (containerRef.current && containerRef.current.children.length > 0) {
        itemWidthRef.current = containerRef.current.children[0].offsetWidth;
      }
    };

    // Calculate on mount and resize.
    calculateItemWidth();
    window.addEventListener("resize", calculateItemWidth);

    return () => {
      window.removeEventListener("resize", calculateItemWidth);
    };
  }, []);

  const handleItemChange = (direction) => {
    // Modified to accept direction
    setCurrentHeroIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) {
        return heroItems.length - 1; // Wrap around to the last item
      } else if (newIndex >= heroItems.length) {
        return 0; // Wrap around to the first item
      } else {
        return newIndex;
      }
    });
  };

  useEffect(() => {
    const currentItem = heroItems[currentHeroIndex];

    const handleItemChange = () => {
      setCurrentHeroIndex((prevIndex) => (prevIndex + 1) % heroItems.length);
    };

    if (currentItem.type === "video") {
      const video = videoRef.current;

      const handleVideoMetadataLoaded = () => {
        if (video) {
          let startTime = null;

          const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;

            if (progress >= video.duration * 1000) {
              handleItemChange();
              cancelAnimationFrame(animationRef.current);
              return;
            }

            animationRef.current = requestAnimationFrame(animate);
          };

          animationRef.current = requestAnimationFrame(animate);
        }
      };

      const handleVideoEnded = () => {
        handleItemChange();
      };

      if (video) {
        video.addEventListener("loadedmetadata", handleVideoMetadataLoaded);
        video.addEventListener("ended", handleVideoEnded);

        if (video.readyState >= 2) {
          handleVideoMetadataLoaded();
        }

        video
          .play()
          .catch((error) => console.error("Error playing video:", error));
      }

      return () => {
        cancelAnimationFrame(animationRef.current); // Use cancelAnimationFrame
        if (video) {
          video.removeEventListener(
            "loadedmetadata",
            handleVideoMetadataLoaded
          );
          video.removeEventListener("ended", handleVideoEnded);
          video.pause();
        }
      };
    } else {
      // It's an image
      const timeout = setTimeout(handleItemChange, 5000);

      return () => clearTimeout(timeout);
    }
  }, [currentHeroIndex]);

  useEffect(() => {
    if (containerRef.current && itemWidthRef.current > 0) {
      containerRef.current.style.transform = `translateX(-${
        currentHeroIndex * itemWidthRef.current
      }px)`;
      containerRef.current.style.transition = "transform 0.5s ease-in-out"; // Add transition
    }
  }, [currentHeroIndex]);

  return (
    <div  className="hero-slider h-[20rem] sm:h-[18rem] ml-3 mr-3 rounded-3xl mt-6 bg-transparent overflow-hidden" ref={heroRef}>
      <div className=" relative w-full h-full">
        <div
          className="hero-imgcont flex transition-none h-full"
          ref={containerRef}
          style={{ width: `${heroItems.length * 100}%` }}
        >
          {" "}
          {/* Container with width */}
          {heroItems.map((item, index) => (
            <div
              key={index}
              style={{ width: `${100 / heroItems.length}%`, height: "100%" }}
            >
              {" "}
              {/* Individual items with width */}
              {item.type === "image" ? (
                <img
                  src={item.src}
                  alt="Hero"
                  className="object-cover w-full h-[20rem] sm:h-[18rem]"
                />
              ) : (
                <video
                  className="object-cover w-full h-[20rem] sm:h-[18rem]"
                  autoPlay
                  muted
                  loop
                  ref={videoRef}
                >
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))}
        </div>
        {/* Navigation Arrows */}
        <div className="navigation-arrows">
        <div className="absolute top-1/2 transform -translate-y-1/2 left-4 z-20">
          <button
            onClick={() => handleItemChange(-1)}
            className="bg-gray-800 bg-opacity-0 rounded-full p-2 text-white hover:bg-transparent"
          >
            <FaChevronLeft className="w-12 h-12" />
          </button>
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-20">
          <button
            onClick={() => handleItemChange(1)}
            className="bg-gray-800 bg-opacity-0 rounded-full p-2 text-white hover:bg-transparent"
          >
            <FaChevronRight className="w-12 h-12" />
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
