import { addDoc, collection } from "firebase/firestore";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import { useState } from "react";
import "./createlistingstyle.css";
import "./CreateProjectStyle.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import {
  /* eslint-disable no-unused-vars */
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import ResidentialHouse from "./ResidentialHouse";
import PentHouse from "./PentHouse";
import Villa from "./Villa";
import BuilderFloorApartment from "./BuilderFloorApartment";
import ResidentialLand from "./ResidentialLand";
import StudioApartment from "./StudioApartment";
import CommercialShop from "./commercialforms/CommercialShop";
import FlatsApartment from "./FlatsApartment";
import PropertyLocation from "./inputcomponents/PropertyLocation";
import OfficeSpace from "./commercialforms/OfficeSpace";
import CommercialShowroom from "./commercialforms/CommercialShowroom";
import FarmHouse from "./commercialforms/FarmHouse";
import AgriculturalLand from "./commercialforms/AgriculturalLand";
import IndustrialShed from "./commercialforms/IndustrialShed";
import IndustrialBuilding from "./commercialforms/IndustrialBuilding";
import IndustrialLand from "./commercialforms/IndustrialLand";
import Godown from "./commercialforms/Godown";
import CommercialLand from "./commercialforms/CommercialLand";

export default function PostProperty() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    WhoUR: "",
    Name: "",
    Mobile: "",
    Email: "",
    PropertyType: "",
    City: "",
    Locality:"",
    Price: "",
    images: {},
    exclusive:"",
    terms:"",
    whatsapp:"",
  });
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error on input change
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "postedProperties"), formData);
      alert("Property submitted successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const { WhoUR, Name, Mobile, Email, PropertyType, City, Locality, Price, images, exclusive, terms, whatsapp} =
    formData;

  function onChange(e) {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }
    // Files
    if (e.target.files) {
      if (e.target.id === "images") {
        setFormData((prevState) => ({
          ...prevState,
          images: e.target.files,
        }));
      }
    }
    // Text/Boolean/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (images.length > 30) {
      setLoading(false);
      toast.error("maximum 30 images are allowed");
      return;
    }

    // const bucket = admin.storage().bucket();
    async function storePDF(pdf) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${pdf.originalname}-${Date.now()}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, pdf);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
              default:
            }
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }

    async function storeImage(image) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
              default:
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }
    if (loading) {
      return <Spinner />;
    }
  }

  const renderFormFields = () => {
    switch (formData.PropertyType) {
      case "flats":
        return (
          <div>
            <FlatsApartment />
          </div>
        );
      case "RH":
        return (
          <div>
            <ResidentialHouse />
          </div>
        );
      case "Villa":
        return (
          <div>
            <Villa />
          </div>
        );
      case "BFA":
        return (
          <div>
            <BuilderFloorApartment />
          </div>
        );
      case "RL":
        return (
          <div>
            <ResidentialLand />
          </div>
        );
      case "PH":
        return (
          <div>
            <PentHouse />
          </div>
        );
      case "SA":
        return (
          <div>
            <StudioApartment />
          </div>
        );
      case "COS":
      case "OIT":
        return (
          <div>
            <OfficeSpace />
          </div>
        );
      case "CS":
        return (
          <div>
            <CommercialShop />
          </div>
        );
      case "CSH":
        return (
          <div>
            <CommercialShowroom />
          </div>
        );
      case "CL":
        return (
          <div>
            <CommercialLand />
          </div>
        );
      case "Godown":
        return (
          <div>
            <Godown />
          </div>
        );
      case "industrial-land":
        return (
          <div>
            <IndustrialLand />
          </div>
        );
      case "industrial-building":
        return (
          <div>
            <IndustrialBuilding />
          </div>
        );
      case "industrial-shed":
        return (
          <div>
            <IndustrialShed />
          </div>
        );
      case "agri-land":
        return (
          <div>
            <AgriculturalLand />
          </div>
        );
      case "farmhouse":
        return (
          <div>
            <FarmHouse />
          </div>
        );
      default:
        return (
          <div>
            <PropertyLocation />
          </div>
        );
    }
  };

  return (
    <main className="bg-gray-50 max-w-full">
      <div className="my-auto mx-10 p-4 min-w-full">
        <h1 className="text-[#2d7fa9] text-3xl text-center mt-6 font-bold">
          Post Your Property For Free!
        </h1>
        <form
          className="p-4 rounded"
          style={{ border: "1px solid black" }}
          onSubmit={onSubmit}
        >
          <div className="mb-6">
            <h1 className="lg mt-6 font-semibold">Personal Details</h1>
            <p className="lg font-semibold">I am</p>
            <TextField
              select
              name="WhoUR"
              label="Who you are?"
              value={formData.WhoUR}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
            >
              <MenuItem value="owner">Owner</MenuItem>
              <MenuItem value="agent">Agent/ Broker</MenuItem>
              <MenuItem value="builder">Builder/ Developer</MenuItem>
            </TextField>

            <p className="lg font-semibold">Name</p>
            <input
              type="text"
              id="Name"
              value={Name}
              onChange={onChange}
              placeholder="Enter Your Name"
              maxLength="32"
              required
              className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />

            <p className="lg font-semibold">Mobile</p>
            <input
              type="number"
              id="Mobile"
              value={Mobile}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10) {
                  onChange(e); // Call your `onChange` function only if the length is valid
                }
              }}
              onBlur={() => {
                if (Mobile.length !== 10) {
                  alert("Please enter a valid 10-digit mobile number.");
                }
              }}
              placeholder="Enter Your Mobile Number"
              required
              className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />

            <p className="lg font-semibold">Email</p>
            <input
              type="email"
              id="Email"
              value={Email}
              onChange={onChange}
              placeholder="Enter Your Email Id"
              required
              className="w-full md:w-1/4 px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          {/* <h1>Property Details Section</h1> */}
          <div>
            <h1 className="lg mt-6 font-semibold">Property Details</h1>
            <p className="lg mt-6 font-semibold">Property Type</p>
            <TextField
              select
              name="PropertyType"
              label="Select Property Type"
              value={formData.PropertyType}
              onChange={handleChange}
              style={{ maxWidth: "400px" }}
              required
              // maxWidth="50px"
              margin="normal"
            >
              <p className="font-semibold">ALL RESIDENTIAL</p>
              <MenuItem value="flats">Flat/ Apartment</MenuItem>
              <MenuItem value="RH">Residential House</MenuItem>
              <MenuItem value="Villa">Villa</MenuItem>
              <MenuItem value="BFA">Builder floor Apartment</MenuItem>
              <MenuItem value="RL">Residential Land/ Plot</MenuItem>
              <MenuItem value="PH">Penthouse</MenuItem>
              <MenuItem value="SA">Studio Apartment</MenuItem>

              <p className="font-semibold">ALL COMMERCIAL</p>
              <MenuItem value="COS">Commercial Office Space</MenuItem>
              <MenuItem value="OIT">Office in IT Park/ SEZ</MenuItem>
              <MenuItem value="CS">Commercial Shop</MenuItem>
              <MenuItem value="CSH">Commercial Showroom</MenuItem>
              <MenuItem value="CL">Commercial Land</MenuItem>
              <MenuItem value="Godown">Warehouse/ Godown</MenuItem>
              <MenuItem value="industrial-land">Industrial Land</MenuItem>
              <MenuItem value="industrial-building">
                Industrial Building
              </MenuItem>
              <MenuItem value="industrial-shed">Industrial Shed</MenuItem>

              <p className="font-semibold">ALL AGRICULTURAL</p>
              <MenuItem value="agri-land">Agicultural Land</MenuItem>
              <MenuItem value="farmhouse">Farm House</MenuItem>
            </TextField>
            {renderFormFields()}
          </div>

          <div>
            <div className="mb-4">
              <label>
                <input
                  type="checkbox"
                  name="exclusive"
                  className="mr-2"
                  required
                  onChange={handleInputChange}
                />{" "}
                I am posting this property 'exclusively' on Property10x
              </label>
            </div>
            <div className="mb-4">
              <label>
                <input
                  type="checkbox"
                  name="terms"
                  className="mr-2"
                  onChange={handleInputChange}
                  required
                />{" "}
                I agree to Property10x T&C, Privacy Policy, & Cookie Policy
              </label>
            </div>
            <div className="mb-4">
              <label>
                <input
                  type="checkbox"
                  name="whatsapp"
                  className="mr-2"
                  required
                  onChange={handleInputChange}
                />{" "}
                I want to receive responses on WhatsApp
              </label>
            </div>
            <button
              type="submit"
              onChange={handleSubmit}
              className="mb-6 w-full px-7 py-3 bg-[#005978] text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              Post Property
            </button>
          </div>
        </form>
      </div>
      <div>
        {/* <h1 className="text-[#005978] mt-0 font-bold ">Advertisement</h1> */}
      </div>
    </main>
  );
}
