import React, { useState } from 'react'

const PantryCafeteria = () => {
const [PantryCafeteria, setPantryCafeteria] = useState('');
  return (
    <div className='gap-10 mt-4'>
       <div className=" gap-8 mt-4  flex flex-wrap">
        <p className="text-gray-600">Pantry/Cafeteria</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="PantryCafeteriaDry">
            <input
              type="radio"
              id="PantryCafeteriaDry"
              name="PantryCafeteria"
              value="Dry"
              checked={PantryCafeteria === 'Dry'}
              onChange={() => setPantryCafeteria('Dry')}
              className="mr-2"
            />
            Dry
          </label>
          <label htmlFor="PantryCafeteriaWet">
            <input
              type="radio"
              id="PantryCafeteriaWet"
              name="PantryCafeteria"
              value="Wet"
              checked={PantryCafeteria === 'Wet'}
              onChange={() => setPantryCafeteria('Wet')}
              className="mr-2"
            />
            Wet
          </label>
          <label htmlFor="PantryCafeteriaNotAvailable">
            <input
              type="radio"
              id="PantryCafeteriaNotAvailable"
              name="PantryCafeteria"
              value="NotAvailable"
              checked={PantryCafeteria === 'NotAvailable'}
              onChange={() => setPantryCafeteria('NotAvailable')}
              className="mr-2"
            />
            Not Available
          </label>
        </div>
      </div>
    </div>
  )
}

export default PantryCafeteria
