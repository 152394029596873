import React from 'react';
import './PropertyFeatures.css';
import PhotoUpload from './inputcomponents/Photos';
import BedRooms from './inputcomponents/BedRooms';
import Balconies from './inputcomponents/Balconies';
import FloorNo from './inputcomponents/FloorNo';
import TotalFloors from './inputcomponents/TotalFloors';
import FurnishedStatus from './inputcomponents/FurnishedStatus';
import BathRooms from './inputcomponents/BathRooms';
import AreaLabel from './labels/AreaLabel';
import CarpetArea from './inputcomponents/CarpetArea';
import SuperArea from './inputcomponents/SuperArea';
import PossessionStatus from './inputcomponents/PossessionStatus';
import Price from './inputcomponents/Price';
import PropertyLocation from './inputcomponents/PropertyLocation';

const PentHouse = () => {

  return (
    <div className="mb-4">
      <PropertyLocation />
      <h1 className="text-lg font-semibold mb-2">Property Features</h1>

      <div className="grid grid-cols-2 gap-4">
        <BedRooms />
        <Balconies />
        <FloorNo />
        <TotalFloors />
        <FurnishedStatus />
        <BathRooms />
      </div>


      <div className="mb-4">
        <AreaLabel />
        <CarpetArea />
        <SuperArea />
      </div>

        <div>
        <h1 className="text-lg font-semibold mb-2 mt-4">
          Transaction Type, Property Availability
        </h1>
        <PossessionStatus />
      </div>
      <div className="mt-4">
        <Price />
        <PhotoUpload />
      </div>
    </div>
  );
};

export default PentHouse;
