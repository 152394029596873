import React, { useState, useRef } from 'react';

function PhotoUpload({ onPhotosChange }) {
  const [photos, setPhotos] = useState([]);
  const fileInputRef = useRef(null);

  const handlePhotoChange = (event) => {
    const newPhotos = Array.from(event.target.files);

    // Validate file types (optional)
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']; // Add more if needed
    const validPhotos = newPhotos.filter(photo => allowedTypes.includes(photo.type));


    if (validPhotos.length < newPhotos.length) {
      alert("Some files were not valid image types and were not added.");
    }
    
    setPhotos(prevPhotos => [...prevPhotos, ...validPhotos]);
    if (onPhotosChange) {
      onPhotosChange([...photos, ...validPhotos]); // Notify parent component
    }
    // Clear the input so the same file can be selected again if needed
    event.target.value = ''; // Important: clear the input
  };

  const handleRemovePhoto = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
    if (onPhotosChange) {
      onPhotosChange(updatedPhotos); // Notify parent
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className='mt-4'>
      <label htmlFor="photo-upload" className="text-lg font-semibold">Upload Photos Here</label> {/* Make it look nice */}
      <span className="text-base text-gray-600 font-thin" > It's Optional! But, don't forget to upload them later.</span>
      <input
        type="file"
        id="photo-upload"
        ref={fileInputRef}
        multiple // Allow multiple file selection
        style={{ display: 'none' }} // Hide the default input
        onChange={handlePhotoChange}
        accept="image/*" // Optional: restrict to image files in the file browser
      />
      <button type="button" className='border-slate-600 border-4 bg-gray-400' onClick={handleUploadClick}>Add Photo</button>

      <div className="photo-preview flex flex-row flex-wrap border border-gray-500 mt-2">
        {photos.map((photo, index) => (
          <div key={index} className="photo-item h-full w-64 m-2 p-1">
            <img
              src={URL.createObjectURL(photo)} // Preview the image
              alt={photo.name}
              className="photo-image border border-gray-950 h-48 w-full object-cover"
            />
            <button type="button" className='remove-photo sticky top-2 bg-gray-400 my-2' onClick={() => handleRemovePhoto(index)}>
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotoUpload;
