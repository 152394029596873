import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import './YoutubeSection.css';
import '../Hero.css';

const YtSlider = () => {
  const ytvideos = [
    {
      thumbnail: "https://i.ytimg.com/vi/cbzm3xPqhBQ/hqdefault.jpg?sqp=-oaymwEnCNACELwBSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLDbkuHlpNo2CYAd93Uti5OB4T6AiQ",
      link: "https://www.youtube.com/embed/cbzm3xPqhBQ",
    },
    {
      thumbnail:
        "https://i.ytimg.com/vi/betmEcDD1F0/hqdefault.jpg?sqp=-oaymwEnCNACELwBSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLBmv-aDvr-aVsYGYsYTCU6M3BGsNg",
      link: "https://www.youtube.com/embed/betmEcDD1F0",
    },
    {
      thumbnail:
        "https://i.ytimg.com/vi/p0qGNYvTALY/hqdefault.jpg?sqp=-oaymwEnCNACELwBSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLBqIXlyt8w494bpZ6ELra4xbggPMQ",
      link: "https://www.youtube.com/embed/p0qGNYvTALY",
    },
    {
      thumbnail:
        "https://i.ytimg.com/vi/cqrcRaktbZE/hqdefault.jpg?sqp=-oaymwEnCNACELwBSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLB1MqAN4COvkFgJWLGXFAd_WIEc2g",
      link: "https://www.youtube.com/embed/cqrcRaktbZE",
    },
  ];
  const [currentHeroIndex, setCurrentHeroIndex] = useState(0);
  const containerRef = useRef(null);
  const itemWidthRef = useRef(0);
  const [showIframe, setShowIframe] = useState(false);
  const [currentVideoLink, setCurrentVideoLink] = useState(null);
  const autoChangeInterval = 5000;

  useEffect(() => {
    const calculateItemWidth = () => {
      if (containerRef.current && containerRef.current.children.length > 0) {
        itemWidthRef.current = containerRef.current.children[0].offsetWidth;
      }
    };

    calculateItemWidth();
    window.addEventListener("resize", calculateItemWidth);

    return () => {
      window.removeEventListener("resize", calculateItemWidth);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentHeroIndex((prevIndex) => (prevIndex + 1) % ytvideos.length);
      setShowIframe(true); // Show iframe on automatic slide change
    }, autoChangeInterval);

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, [ytvideos.length]); // Add ytvideos.length to dependency array


  const handleItemChange = (direction) => {
    clearInterval(autoChangeInterval); // Stop auto-change when manually changing
    setCurrentHeroIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      if (newIndex < 0) {
        return ytvideos.length - 1;
      } else if (newIndex >= ytvideos.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
    setShowIframe(false); // Hide iframe when changing slides
  };

  const handleThumbnailClick = (link) => {
    setCurrentVideoLink(link);
    setShowIframe(true);
  };

  useEffect(() => {
    if (containerRef.current && itemWidthRef.current > 0) {
      containerRef.current.style.transform = `translateX(-${currentHeroIndex * itemWidthRef.current}px)`;
      containerRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentHeroIndex]);

  return (
    <div className="ytslider h-[32rem] sm:h-[22rem] ml-3 mr-3 rounded-3xl mt-6 bg-transparent overflow-hidden relative">
      <div className="relative w-full h-full">
        <div
          className="hero-imgcont flex transition-none h-full"
          ref={containerRef}
          style={{ width: `${ytvideos.length * 100}%` }}
        >
          {ytvideos.map((video, index) => (
            <div
              key={index}
              style={{ width: `${100 / ytvideos.length}%`, height: "100%" }}
            >
              <img
                src={video.thumbnail}
                alt={`Thumbnail ${index + 1}`}
                className="object-fit w-full h-full sm:h-full cursor-pointer"
                onClick={() => handleThumbnailClick(video.link)}
              />
            </div>
          ))}
        </div>

        {/* Iframe Overlay */}
        {showIframe && currentVideoLink && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80  flex items-center justify-center">
            <iframe
              title="YouTube video player"
              className="w-full h-full" // Make iframe responsive
              src={currentVideoLink}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <button onClick={() => setShowIframe(false)} className="absolute bottom-2 w-16 rounded-full bg-gray-600 hover:bg-blue-600 text-white z-0">Close</button>
          </div>
        )}


        {/* Navigation Arrows */}
        <div className="navigation-arrows">
        <div className="absolute top-1/2 transform -translate-y-1/2 left-4 z-20">
          <button
            onClick={() => handleItemChange(-1)}
            className="bg-gray-800 bg-opacity-30 rounded-full p-2 text-white hover:bg-blue-500"
          >
            <FaChevronLeft className="" />
          </button>
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-20">
          <button
            onClick={() => handleItemChange(1)}
            className="bg-gray-800 bg-opacity-30 rounded-full p-2 text-white hover:bg-blue-500"
          >
            <FaChevronRight className="" />
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default YtSlider;


