import React, { useState } from 'react';
import ResidentialProjects from './ResidentialProjects.jsx';
import CommercialProjects from './CommercialProjects.jsx';
import AgriculturalProjects from './AgriculturalProjects.jsx';

function PropertyForm() {
  const [selectedOption, setSelectedOption] = useState("");
  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  return (
    <>
    <div>
         <select id="optionSelect" value={selectedOption} onChange={handleOptionChange}>
        <option value="default">Select an option</option>
        <option value="commercial_projects">Commercial Project</option>
        <option value="residential_project">Residential Project</option>
        <option value="agricultural_project">Agricultural Project</option>
        </select>
      {selectedOption === 'commercial_projects' && <CommercialProjects  />}
      {selectedOption === 'residential_project' && <ResidentialProjects />}
      {selectedOption === 'agricultural_project' && <AgriculturalProjects />}  
       
    </div>
    </>
  );
}

export default PropertyForm;