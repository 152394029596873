import React, { useState } from 'react'

const BathRooms = () => {
  const [bathrooms, setBathrooms] = useState("");
  return (
    <div>
    <label htmlFor="bathrooms" className="block mb-1">
      Bathrooms:
    </label>
    <select
      id="bathrooms"
      value={bathrooms}
      onChange={(e) => setBathrooms(e.target.value)}
      required
      className="border border-gray-300 rounded-md p-2"
    >
      <option value="default">Select an Option</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="3+">3+</option>
    </select>
  </div>
  )
}

export default BathRooms
