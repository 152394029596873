import React, { useState, useEffect } from "react";
import truckincube from "../assets/svg/41739429.jpg";
import box from "../assets/svg/package.png";
import movingbox from "../assets/svg/moving.png";
import sheet from "../assets/svg/cutting.png";
import plant from "../assets/svg/customer-service.png";
import stand from "../assets/svg/moving-truck.png";
import International from "../assets/svg/courier-cut-out-mobile-phone-shipping-pile.jpg";
import Industrial from "../assets/svg/industrialmoving.png";
import Commercial from "../assets/svg/commericialmoving.jpeg";
import Warehousing from "../assets/svg/warehousing.png";
import Household from "../assets/svg/house.png";
import Domestic from "../assets/svg/domestic.png";
import { Link } from "react-router-dom";
import moverss from "../assets/svg/movers.jpg";
import { FaLinkedinIn } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Prop from "../assets/svg/Prop.jpeg";
import "./Move.css";
import SocialIcon from "../components/SocialIcon";
const Move = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission here
  };
  return (
    <>
      <FloatingWhatsApp
        accountName="Property10X"
        phoneNumber="9871424020"
        avatar={Prop}
        statusMessage="Replies within a minute"
        chatMessage="Welcome to Property10X"
        allowClickAway={true}
      />
      <div>
        <h2 className="text-center text-2xl text-[#005978] font-bold">
          Movers & Packers
        </h2>
      </div>
      <div className="moveimg mt-5">
        <div className="moveform">
          <form className="movefo" onSubmit={handleSubmit}>
            <h2 className="text-[#005978] font-bold text-2xl">
              Meet our services
            </h2>
            <div>
              <div>
                <label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name*"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    pattern="[0-9]{10}"
                    placeholder="Enter your phone number*"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email*"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="text"
                    value={propertyName}
                    onChange={(e) => setPropertyName(e.target.value)}
                    placeholder="Location From*"
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Location To*"
                    required
                  />
                </label>
              </div>
              <button className="movebutton" type="submit">
                Get a Call
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="quote">
        <blockquote>
          "Moving is no easy feat, but the right movers and packers can turn it
          into a seamless and stress-free journey. By entrusting your belongings
          to skilled professionals who handle them with utmost care and
          efficiency, you can enjoy peace of mind knowing that your move is in
          good hands."
        </blockquote>
      </div>
      <div className="flex items-center lg:justify-between flex-col lg:flex-row mt-8 lg:mt-4 m-20">
        <div className="flex flex-col mt-4 lg:mt-0">
          <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
            10+
          </span>
          <span className="text-s font-semibold">Years of Trust</span>
        </div>
        <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>
        <div className="flex flex-col mt-8 lg:mt-0">
          <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
            500+
          </span>
          <span className="text-s font-semibold text-center">Vehicles</span>
        </div>
        <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>
        <div className="flex flex-col mt-8 lg:mt-0">
          <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
            1000+
          </span>
          <span className="text-s font-semibold text-center">
            Moves Annually
          </span>
        </div>
      </div>
      <div>
        <h2 className="sp">
          At the heart❤️ of our company is a commitment to innovation.
        </h2>
      </div>
      <div>
        <p className=" text-center">
          Amenities refer to the facilities and services that are provided in a
          particular setting to enhance the comfort and convenience of the
          people using them.
        </p>
      </div>
      <div className="ami">
        <div className="amenityy">
          <img src={truckincube} alt="cube" />
          <p> Cube</p>
        </div>
        <div className="amenityy">
          <img src={box} alt="Park" />
          <p>LED Box</p>
        </div>

        <div className="amenityy">
          <img src={movingbox} alt="moving house" />
          <p>Moving Box</p>
        </div>

        <div className="amenityy">
          <img src={sheet} alt="fabric sheet" />
          <p>Fabric Sheet</p>
        </div>

        <div className="amenityy">
          <img src={plant} alt="Services" />
          <p>services</p>
        </div>

        <div className="amenityy">
          <img src={stand} alt="bike stand" />
          <p>Bike stand</p>
        </div>
      </div>
      <div className="electriccontainer">
        <div className="electriccontent">
          <h2 className="text-5xl font-bold text-start text-[#005978]">
            Movers & Packers!
          </h2>
          <p className="mt-5 text-lg font-semibold">
            Good movers and packers don't just move your belongings, they move
            your life.
          </p>
          <Link to="/sign-in">
            <button className="homebutton mt-8">Book now</button>
          </Link>
        </div>
        <div className="electricimage">
          <img className="imm" src={moverss} alt="Image"></img>
        </div>
      </div>
      <div>
        <h2 className="sp">Our Core Services</h2>
      </div>
      <div>
        <p className=" text-center">
          Our core services are designed to deliver exceptional solutions that
          meet your needs and exceed your expectations.
        </p>
      </div>
      <div className="ami">
        <div className="amenityy">
          <img src={International} alt="cube" />
          <p> International</p>
        </div>
        <div className="amenityy">
          <img src={Domestic} alt="Park" />
          <p>Domestic</p>
        </div>

        <div className="amenityy">
          <img src={Household} alt="moving house" />
          <p>Household </p>
        </div>

        <div className="amenityy">
          <img src={Industrial} alt="fabric sheet" />
          <p>Industrial</p>
        </div>

        <div className="amenityy">
          <img src={Commercial} alt="Services" />
          <p>Commercial</p>
        </div>

        <div className="amenityy">
          <img src={Warehousing} alt="bike stand" />
          <p>Warehousing</p>
        </div>
      </div>
      <div>
        <h2 className="sp">What we offer!💰</h2>
        <Link to="/sign-in">
          <button className="homebuttonn">Book free consultation</button>
        </Link>
      </div>
      <div className=" text-center text-[#005978] font-bold text-3xl m-10">
        Connect with us
        <SocialIcon />
      </div>
    </>
  );
};

export default Move;
