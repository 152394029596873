import React from "react";
import "./YoutubeSection.css";
// import "../Search.css";
import { FaYoutube } from "react-icons/fa";

const YtDescription = () => {
  return (
    <div className="">
      <div className="yt-section flex flex-col pt-5 my-10 z-10 items-left">
        {/* <p className="search-heading text-[#234755] text-[32px] sm:text-[55px] font-bold bg-white rounded p-3">
        Each to their own abode.
      </p> */}
        <div className="search-heading">
          <h1 className="yt-h1 mx-5 text-[24px] text-left text-white max-w-2xl sm:text-[34px]">
            Our Virtual Tours: Explore Your Future Home <br />
            <span className="p-span p-0">Step Inside Our Latest Listings!</span>
          </h1>{" "}
          {/* <span className="heading-span pt-2 pl-2 text-[20px] sm:pt-0 sm:text-[25px] lg:mt-2">
            Property10x
          </span> */}
          <p className="yt-p justify-center mx-5 text-[24px] text-slate-50 max-w-4xl text-left">
            Uncover a world of exclusive property showcases and luxury lifestyle
            insights. From breathtaking property tours to expert advice, we
            offer an unparalleled experience, showcasing the exceptional quality
            and distinctive lifestyle that defines us.
          </p>
        </div>
        <div className="yt-btn mt-56 max-w-xs">
          <a
            href="https://www.youtube.com/@property10x"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <button className=" bg-red-600 rounded-full px-10 p-0 font-bold flex flex-row items-center justify-center hover:bg-red-500">
              <FaYoutube className="justify-center text-[44px] m-0 p-0 pr-2" />
              Subscribe Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default YtDescription;
