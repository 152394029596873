import React, { useEffect } from "react";
import { useState } from "react";
import "./Aboutus.css";
import Waseem from "../assets/svg/waseem.jpeg";
import Shaily from "../assets/svg/shaily copy.jpeg";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { FaThreads } from "react-icons/fa6";
import ReactGA from "react-ga";
import EmployeeCards from "../components/EmployeeCards";
import SectionSlider from "../components/SectionSlider";
import SocialIcon from "../components/SocialIcon";
const TRACKING_ID = "G-P4PT1KJLDT";
ReactGA.initialize(TRACKING_ID);
function initializeReactGA() {
  ReactGA.initialize("G-P4PT1KJLDT");
  ReactGA.pageview(window.location.pathname + window.location.search);
}
const AboutUs = () => {
  useEffect(() => {
    initializeReactGA();
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Subscribing email address: ${email}`);
    setEmail("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <>
      <div className="about-us display-copy overflow-x-hidden">
        <div className="description">
          <h1>About Our Company</h1>
          <p>
            Property 10X is a real estate company that aims to help individuals
            and organizations achieve financial freedom through property
            investment. The company offers a range of services including
            property sales, property management, and property investment
            training & We offer a 360° solution for Real-Estate industry through
            our Learn, Collaborate and Earn Model. We believe in creating
            entrepreneurs and providing an innovative ecosystem to leverage the
            power of the Indian Real-Estate industry & providing opportunities
            for creating wealth.
          </p>
        </div>
        <h2 className="Meet">Meet Our Creators</h2>
        <div className="container fondcont ">
          <div className="box fonbox">
            <div>
              <div className="">
                <img className="fonimg" src={Waseem} alt=" Waseem" />
              </div>{" "}
              <div className="social-media">
                <a href="https://www.linkedin.com/in/waseem-akram-851385151/ ">
                  <i className="FaLinkedinIn"></i>
                  <FaLinkedinIn />
                </a>
                <a href="https://www.instagram.com/waseem_property10x/">
                  <i className="FiInstagram"></i>
                  <FiInstagram />
                </a>
                <a href=" # ">
                  <i className="BsFacebook"></i>
                  <BsFacebook />
                </a>
                <a href=" # ">
                  <i className="FaXTwitter"></i>
                  <FaXTwitter />
                </a>
              </div>
            </div>
            <div className="fondetails py-6 pl-10  px-3">
              {" "}
              <h3 className="fonh p-0">Waseem Akram</h3>
              <p className="detail pb-2">CEO & Founder</p>
              <p className="description text-left justify-center text-sm">
                Waseem has over a decade of experience in the real estate
                industry and startup ecosystem, establishing himself as a
                seasoned professional with deep industry expertise. His
                strategic vision and ability to navigate the complexities of
                real estate have made him a trusted leader in the field. <br />{" "}
                <br />A proficient business leader with experience at KPMG,
                Waseem brings a strong foundation in business growth and
                development. His certification from Australian Deccan University
                further enhances his credentials, solidifying his reputation as
                a knowledgeable and reliable industry expert.
              </p>
            </div>
          </div>
          <div className="box fonbox">
            <div className="">
              <div className="">
                <img className="fonimg" src={Shaily} alt="Shaily" />
              </div>{" "}
              <div className="social-media">
                <a href="https://www.linkedin.com/in/shailyshaily/">
                  <i className="FaLinkedinIn"></i>
                  <FaLinkedinIn />
                </a>
                <a href="https://www.instagram.com/shaily_madaan11/">
                  <i className="FiInstagram"></i>
                  <FiInstagram />
                </a>
                <a href=" # ">
                  <i className="BsFacebook"></i>
                  <BsFacebook />
                </a>
                <a href=" # ">
                  <i className="FaXTwitter"></i>
                  <FaXTwitter />
                </a>
              </div>
            </div>
            <div className="fondetails py-6 pl-10  px-3">
              {" "}
              <h3 className="fonh p-0">Shaily Madaan</h3>
              <p className="detail pb-2">Co-Founder & Director</p>
              <p className="description text-left justify-center text-sm">
                Shaily, with over five years of experience in the real estate
                industry, has established herself as a knowledgeable and skilled
                professional. Her expertise in the market, combined with a keen
                eye for detail, allows her to guide clients seamlessly through
                buying and selling processes.
                <br />
                <br /> Known for her commitment to personalized service, Shaily
                takes the time to understand each client’s unique needs,
                ensuring a smooth and rewarding real estate experience. Her
                dedication and reliability have earned her a strong reputation
                as a trusted real estate professional.
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center lg:justify-between flex-col lg:flex-row mt-8 lg:mt-4 m-20">
          <div className="flex flex-col mt-4 lg:mt-0">
            <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
              6000+
            </span>
            <span className="text-s font-semibold">Strong Investor Base</span>
          </div>

          <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>

          <div className="flex flex-col mt-8 lg:mt-0">
            <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
              10 Years+
            </span>
            <span className="text-s font-semibold text-center">
              of Valuable industry experience
            </span>
          </div>

          <div className="hidden lg:block border-l-2 border-[#005978] h-12 my-auto mx-8"></div>

          <div className="flex flex-col mt-8 lg:mt-0">
            <span className="text-3xl text-[#005978] font-bold text-center lg:text-left">
              150 Cr+
            </span>
            <span className="text-s font-semibold text-center">
              Worth high-value transaction
            </span>
          </div>
        </div>
        <div>
          <blockquote className="quote">
            "At our company, we believe that real estate is not just about
            buying, selling, or renting properties. It's about creating new
            beginnings and building a better future for our clients."
          </blockquote>
        </div>
        <div className="employees">
          <h2 className="Team">Our Strength</h2>
          <p className="Des">
            Our team is our greatest strength. Each of our employees is a highly
            skilled and experienced professional, dedicated to providing the
            best possible service to our clients & they are passionate about
            what they do, and this is reflected in the exceptional work they
            deliver. We value the expertise and unique perspectives that each
            team member brings to the table, and we believe that this diversity
            is one of our greatest assets.
          </p>
          {/* <div className="employee-cards flex flex-row">
        <div class="employee-container">
          <div class="employee">
            <div class="employee-image">
              <img src={fakeicon} alt="faizan" />
            </div>
            <h3>Faizan Qureshi</h3>
            <p className="text-black">Business Deveopment Executive</p>
            <div class="social-media-icons">
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-linkedin"></i>
              </a>
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-instagram"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="employee-container">
          <div class="employee">
            <div class="employee-image">
              <img src={fakeicon} alt="faizan" />
            </div>
            <h3>Mr. Shohaib</h3>
            <p className="text-black">Business Deveopment Executive</p>
            <div class="social-media-icons">
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-linkedin"></i>
              </a>
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-instagram"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="employee-container">
          <div class="employee">
            <div class="employee-image">
              <img src={fakeicon} alt="faizan" />
            </div>
            <h3>Mr. Irfan</h3>
            <p className="text-black">Digital Marketing Expert</p>
            <div class="social-media-icons">
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-linkedin"></i>
              </a>
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-instagram"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="employee-container">
          <div class="employee">
            <div class="employee-image">
              <img src={fakeicon} alt="faizan" />
            </div>
            <h3>Kapil Raghav</h3>
            <p className="text-black">Business Deveopment Executive</p>
            <div class="social-media-icons">
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-linkedin"></i>
              </a>
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-instagram"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="employee-container">
          <div class="employee">
            <div class="employee-image">
              <img src={fakeicon} alt="faizan" />
            </div>
            <h3>Abhay Kumar</h3>
            <p className="text-black">Full Stack Developer</p>
            <div class="social-media-icons">
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-linkedin"></i>
              </a>
              <a href=" " target="_blank" rel="noreferrer">
                <i class="fab fa-instagram"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-facebook"></i>
              </a>
              <a href=" " target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        </div> */} 
          {/* <marquee behavior="active" direction="left" className="text-silver-500 font-semibold" > */}
          <EmployeeCards />
          {/* </marquee> */}
          {/* <SectionSlider /> */}
          <div className="soc">
            Our Social Media Handle
            <SocialIcon />
            </div>
          <div className="subscribe">
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleEmailChange}
              />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
