/* eslint-disable no-unused-vars */
import "./ContactUs.css";
import React, { useEffect } from "react";
import Contactimg from "../assets/svg/contactus.png";
import upperimage from "../assets/svg/uu.jpg";
import { useState } from "react";
import ReactGA from "react-ga";
const TRACKING_ID = "G-P4PT1KJLDT";
ReactGA.initialize(TRACKING_ID);
function initializeReactGA() {
  ReactGA.initialize("G-P4PT1KJLDT");
  ReactGA.pageview(window.location.pathname + window.location.search);
}
const ContactForm = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    initializeReactGA();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  function Submit(e) {
    e.preventDefault(); // Prevent the default form submission behaviour
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    fetch(
      "https://script.google.com/macros/s/AKfycbzrJIIslg4En3SobcXY0VG-txsVY5Bw7BBrSXLHn-fcDcMIl2Ho7UzV8LFEvE8cMlV9XQ/exec",
      {
        method: "POST",
        body: formDatab, // Send form data as JSON string.
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
    var myHeaders = new Headers();
    myHeaders.append("Authkey", "eab7419d9f6b20f8276cd911257f2a411698836658");

    var formdata = new FormData();
    formdata.append("name", formDatab.get("Name"));
    console.log(formDatab.get("Name"));
    formdata.append("contact_no", formDatab.get("Phone"));
    console.log(formDatab.get("Phone"));
    formdata.append("contact_email", formDatab.get("Email"));
    console.log(formDatab.get("Email"));
    formdata.append("message", formDatab.get("Message"));
    console.log(formDatab.get("Message"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://services.workveu.com/integration/web/api", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }

  return (
    <div>
      <div className="contact-containerss">
        <img src={upperimage} alt="Contact Us" className="contact-imagee" />
        <h1 className="contact-heading">Contact Us</h1>
      </div>
      <div className="container contcont">
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p>
            <i className="fas fa-map-marker-alt mr-3"></i>201304, Sector-135,
            Noida (Uttar Pradesh), India
          </p>
          <p>
            <i className="fas fa-map-marker-alt mr-3"></i>Tower-4, Office-719,
            Assotech business cresterra
          </p>
          <p>
            <i className="fas fa-phone mr-3"></i>+91-9871424020
          </p>
          <p>
            <i className="far fa-envelope mr-3"></i> info.prop10x@gmail.com
          </p>
          {/* Social media links */}
          <div className="social-links">
            <h1 className="connect-h">Connect with us on social platform</h1>
            <a
              href="https://www.facebook.com/theproperty10x?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://x.com/property10x "
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/the_property10x?igsh=MTZhMDF5anFicTByZQ%3D%3D&utm_source=qr "
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/@property10x"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/property-10x/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
            href=" https://www.threads.net/@the_property10x?igshid=NTc4MTIwNjQ2YQ== "
            target="_blank"
              rel="noopener noreferrer"
          >
            <i className="fab fa-threads"></i>
          </a>
          </div>
        </div>
        <div className="form-container conts">
          <form className="contact-form" onSubmit={Submit}>
            {/* <h1 className="form-title">Contact Us</h1> */}
            <label className="form-label">
              <span className="mandatory">
                Name<span className="text-[#FF0000]">*</span>
              </span>
              <input
                type="text"
                name="Name"
                className="form-input"
                placeholder="Enter your name*"
                required
              />
            </label>
            <label className="form-label">
              <span className="mandatory">
                Mobile Number<span className="text-[#FF0000]">*</span>
              </span>
              <input
                type="text"
                name="Phone"
                className="form-input"
                placeholder="Enter your Mobile Number*"
                required
              />
            </label>
            <label className="form-label">
              <span className="">
                Email<span className="text-[#FF0000]">*</span>
              </span>
              <input
                type="text"
                name="Email"
                className="form-input"
                placeholder="Enter your email address"
                required
              />
            </label>
            <label className="form-label">
              <span className="mandatory">
                Message<span className="text-[#FF0000]">*</span>
              </span>
              <input
                type="text"
                name="Message"
                className="form-input form-textarea"
                placeholder="Enter your query*"
                required
              />
            </label>
            <input
              type="submit"
              className="form-submit"
              value="Send"
              name="Name"
            />
          </form>
        </div>
      </div>
      <div className="bg-[#005978]">
        <h1 className="location-heading">Discovering Your Destination.</h1>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14025.773951861735!2d77.38341617346424!3d28.496301853894927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce860bfea95d9%3A0x683ddf48f263ecc!2sAssotech%20Business%20Cresterra!5e0!3m2!1sen!2sin!4v1696052175367!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Map"
        ></iframe>
      </div>
    </div>
  );
};
export default ContactForm;
