import React, { useState } from 'react'

const BedRooms = () => {
  const [bedrooms, setBedrooms] = useState("");
  return (
    <div>
    <label htmlFor="bedrooms" className="block mb-1">
      Bedrooms:
    </label>
    <select
      id="bedrooms"
      value={bedrooms}
      onChange={(e) => setBedrooms(e.target.value)}
      required
      className="border border-gray-300 rounded-md p-2"
    >
      <option value="default">Select an Option</option>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5+">5+</option>
    </select>
  </div>
  )
}

export default BedRooms
