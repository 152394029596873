import React, { useState } from 'react'

const OpenSides = () => {
  const [openSides, setOpenSides] = useState('');
  return (
    <div>
       <div>
          <label htmlFor="openSides" className="block mb-4">
            No of open sides:
          </label>
          <select
            id="openSides"
            value={openSides}
            onChange={(e) => setOpenSides(e.target.value)}
            required
            className="border border-gray-300 rounded-md p-2 w-48 mb-4"
          >
            <option value="default">Select an Option</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">3+</option>
            </select>
        </div>
    </div>
  )
}

export default OpenSides
