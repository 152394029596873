import React from 'react'
import YtDescription from './YtDescription'
import YtSlider from './YtSlider'

const YoutubeSection = () => {
  return (
    <div>
      <div className="yt-section bg-gray-600 rounded-3xl">
      <div className="yt-box flex flex-row justify-around m-10">
        <div className="yt-box disable-copy">
          <YtDescription />
        </div>
        <div className="yt-slider mt-10 max-w-lg">
          <YtSlider />
        </div>
      </div>
    </div>
    </div>
  )
}

export default YoutubeSection
