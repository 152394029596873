import React, { useState } from 'react'

const TotalFloors = () => {
  const [totalFloors, setTotalFloors] = useState("");
  return (
    <div>
    <label htmlFor="totalFloors" className="block mb-1">
      Total Floors:
    </label>
    <select
      id="totalFloors"
      value={totalFloors}
      onChange={(e) => setTotalFloors(e.target.value)}
      className="border border-gray-300 rounded-md p-2"
    >
      <option value="default">Select an Option</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="13+">13+</option>
    </select>
  </div>
  )
}

export default TotalFloors
