import React, { useEffect, useRef, useState } from 'react';
import '../pages/Aboutus.css';
import fakeicon from '../assets/svg/fakeimage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

const EmployeeCards = () => {
    const [currentHeroIndex, setCurrentHeroIndex] = useState(0);

    const employeeData = [
        { type: 'image', name: 'Irfan Siddiqui', title: 'Business Development Manager', phone: '', email: '', linkedin: 'https://www.linkedin.com/in/your-linkedin-profile', image: require('../assets/svg/irfan1.jpg') },
        { type: 'image', name: 'Suheb Qureshi', title: 'Business Deveopment Executive', phone: '', email: '', linkedin: 'https://www.linkedin.com/in/your-linkedin-profile', image: require('../assets/svg/suheb.jpg') },
        { type: 'image', name: 'Faizan Qureshi', title: 'Business Deveopment Executive', phone: '', email: '', linkedin: 'https://www.linkedin.com/in/your-linkedin-profile', image: require('../assets/svg/faizan.jpg') },
        { type: 'image', name: 'Kapil Raghav', title: 'Business Deveopment Executive', phone: '', email: '', linkedin: 'https://www.linkedin.com/in/your-linkedin-profile', image: require('../assets/svg/kapil.jpg') },
        { type: 'image', name: 'Abhay Kumar', title: 'Full Stack Developer', phone: '', email: 'abhay@theproperty10x.com', linkedin: 'https://www.linkedin.com/in/your-linkedin-profile', image: require('../assets/svg/abhay1.jpg') },
    ];

    const [autoScroll, setAutoScroll] = useState(true);
    const containerRef = useRef(null);
    const itemWidthRef = useRef(0);
    const autoScrollRef = useRef(null);


    useEffect(() => {
        const calculateItemWidth = () => {
            if (containerRef.current && containerRef.current.children.length > 0) {
                itemWidthRef.current = containerRef.current.children[0].offsetWidth;
            }
        };

        calculateItemWidth();
        window.addEventListener("resize", calculateItemWidth);

        return () => {
            window.removeEventListener("resize", calculateItemWidth);
            clearTimeout(autoScrollRef.current); // Clear timeout on unmount
        };
    }, []);

    const handleItemChange = (direction) => {
        setCurrentHeroIndex((prevIndex) => {
            const newIndex = prevIndex + direction;
            return (newIndex < 0 ? employeeData.length - 1 : newIndex % employeeData.length);
        });
    };

    useEffect(() => {
        if (containerRef.current && itemWidthRef.current > 0) {
            containerRef.current.style.transform = `translateX(-${currentHeroIndex * itemWidthRef.current}px)`;
            containerRef.current.style.transition = "transform 0.5s ease-in-out";
        }
    }, [currentHeroIndex]);


  //   useEffect(() => {
  //     clearTimeout(autoScrollRef.current); // Clear any existing timeout

  //     if (autoScroll) { // Only set timeout if autoScroll is true
  //         autoScrollRef.current = setTimeout(() => {
  //             handleItemChange(1);
  //         }, 5000);
  //     }

  //     return () => clearTimeout(autoScrollRef.current);
  // }, [currentHeroIndex, autoScroll]);




    return (
        <div className="relative w-full h-full">
    <div className="employee-cards flex flex-row shrink-0 gap-4 w-1/2 md:w-1/4 lg:w-1/5 xl:w-1/5">
                <div
                    className="hero-imgcon flex transition-none h-full"
                    ref={containerRef}
                    style={{ width: `${employeeData.length * 100}%` }}
                >
                    {employeeData.map((employee, index) => (
                        <div
                            key={index}
                            style={{ width: `${100 / employeeData.length}%`, height: "100%" }}
                        >
                            <div className="employee-container shrink-0">
                                <div className="employee">
                                    <div className="employee-image">
                                        <img src={employee.image || fakeicon} alt={employee.name} />
                                    </div>
                                    <h3>{employee.name}</h3>
                                    <p className="text-black">{employee.title}</p>
                                    <div className="social-media-icons flex flex-row">
                                        <a href={`tel:${employee.phone}`} rel="noreferrer" className='text-black'>
                                            <FontAwesomeIcon icon={faPhone} className='text-black w-full' />
                                        </a>
                                        <a href={`mailto:${employee.email}`} rel="noreferrer">
                                            <FontAwesomeIcon icon={faEnvelope} className='text-black' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Navigation Arrows */}
            <div className="absolute top-1/2 transform -translate-y-1/2 left-4 z-20">
                <button
                    onClick={() => handleItemChange(-1)}
                    className="bg-gray-800 bg-opacity-20 rounded-full p-2 text-white hover:bg-bg-blue-100"
                >
                    <FaChevronLeft className="w-12 h-12" />
                </button>
            </div>
            <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-20">
                <button
                    onClick={() => handleItemChange(1)}
                    className="bg-gray-800 bg-opacity-20 rounded-full p-2 text-white hover:bg-bg-blue-100"
                >
                    <FaChevronRight className="w-12 h-12" />
                </button>
            </div>
            
        </div>
    );
};

export default EmployeeCards;