import React, { useState } from 'react'

const BoundaryWall = () => {
    const [boundaryWall, setBoundaryWall] = useState('');
  return (
    <div className="gap-8 pb-2" >
          <div className='mt-2 gap-10 flex flex-wrap'>
        <p className="text-gray-600">Boundary wall made</p>
        <div className="flex items-center space-x-4 mt-0">
          <label htmlFor="boundaryWallYes">
            <input
              type="radio"
              id="boundaryWallYes"
              name="boundaryWall"
              value={true}
              checked={boundaryWall}
              onChange={() => setBoundaryWall(true)}
              className="mr-2"
            />
            Yes
          </label>
          <label htmlFor="boundaryWallNo">
            <input
              type="radio"
              id="boundaryWallNo"
              name="boundaryWall"
              value={false}
              checked={!boundaryWall}
              onChange={() => setBoundaryWall(false)}
              className="mr-2"
            />
            No
          </label>
        </div>
      </div>
    </div>
  )
}

export default BoundaryWall
