import React from 'react';
import { Link } from 'react-router-dom';

const HeroBtn = ({ title, underline, css, route }) => {
  return (
    <div className={` relative group`}>
      <div className="renderbuttons font-semibold px-2 text-[15px]">
        <Link to={route}>
          <button>{title}</button>
        </Link>
      </div>
      <div
        className={`absolute h-[2.5px] group-hover:bg-[#e20112] ${underline} w-full duration-200 ease-out`}
      ></div>
    </div>
  );
};
export default HeroBtn;