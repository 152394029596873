import React, { useEffect } from "react";
import training from "../assets/svg/training.jpg";
import "../components/Training.css";
import { Link } from "react-router-dom";
import Waseem from "../assets/svg/waseem.jpeg";
import shaily from "../assets/svg/shailymadaan.jpeg";
import Chiranjeev from "../assets/svg/chiranjeev.jpeg";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Prop from "../assets/svg/Prop.jpeg";
import { FaThreads, FaXTwitter } from "react-icons/fa6";
const Training = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <>
      <FloatingWhatsApp
        accountName="Property 10X"
        phoneNumber="9871424020"
        avatar={Prop}
        notification
        notificationSound
        statusMessage="Replies within a minute"
        allowEsc
        chatMessage="Hello there! 🤝 
How can we help?"
        allowClickAway={true}
      />
      <div className="training-container">
        <div className="text-container training-start">
          <h2 className="training-h2">Start Your Journey</h2>
          <p className="training-p">
            Train for growth, success is both the journey and the destination.
          </p>
          <button className="button training-button">
            <Link to="/contactus" className="button-link font-bold" target="_blank">
              <div>Train Yourself</div>
            </Link>
          </button>
        </div>
        <div className="training-image-container">
          <img className="training-img" src={training} alt="Exploring" />
        </div>
      </div>
      <Link to="/contactus">
        <div className="container contoffer">
          <div className="offer">
            <span>
              ***Premium Aerocity Available-The Dream Residential Project🏠
            </span>
          </div>
        </div>
      </Link>
      <div>
        <h1 className="  font-extrabold text-center text-4xl mt-20 text-[#005978]">
          Meet your Mentors
        </h1>
      </div>
      <div className="cards-row">
        {/* <div className="Training-cards">
    <img src={Chiranjeev} alt="Mentor 1"></img>
    <div className="names">Chirranjiev Khanna</div>
    </div> */}
        <div className="Training-cards">
          <img src={Waseem} alt="Mentor 1"></img>
          <div className="names">Waseeem Akram</div>
        </div>
        <div className="Training-cards">
          <img src={shaily} alt="Mentor 1"></img>
          <div className="names">Shaily Madaan</div>
        </div>
      </div>
      <div className="soci">
        Our Social Media Handle
        <div className="social-media">
          <a href=" https://www.youtube.com/@property10x" target="_blank">
            <i className="fab fa-youtube"></i>
          </a>
          <a
            href=" https://www.instagram.com/the_property10x?igsh=MTZhMDF5anFicTByZQ%3D%3D&utm_source=qr "
            target="_blank"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href=" https://www.facebook.com/theproperty10x?mibextid=LQQJ4d"
            target="_blank"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href=" https://www.linkedin.com/company/property-10x/"
            target="_blank"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a href=" https://x.com/property10x " target="_blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href=" https://www.threads.net/@the_property10x?igshid=NTc4MTIwNjQ2YQ== "
            target="_blank"
          >
            <i className="fab fa-threads"></i>
            {/* <FaThreads />  */}
          </a>
        </div>
      </div>
    </>
  );
};
export default Training;
